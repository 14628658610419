import React, {useEffect, useState} from 'react';

import ExerciceCard from './ExerciceCard';
import NoExercices from './components/NoExercices';

import {getExerciseDifficulty, getFilteredExercises} from 'utils/exercices';

function ExerciceContainer({
  data,
  exercices,
  analysisTexts,
  locked,
  modalRef,
  onBoarding,
}) {
  const difficulty = getExerciseDifficulty(data?.score);
  const [exercicesData, setExercicesData] = useState([]);

  useEffect(() => {
    const getExercises = async () => {
      if (!exercices) return;
      const filteredExercises = getFilteredExercises(
        exercices,
        data?.exercice,
        difficulty,
        data?.type,
      );
      setExercicesData(filteredExercises);
    };

    getExercises();
  }, [data, difficulty, exercices]);

  function renderItem(item, key) {
    if (key === 0)
      return (
        <ExerciceCard
          item={item}
          key={key}
          analysisTexts={analysisTexts}
          onBoarding={onBoarding}
        />
      );
    else
      return (
        <ExerciceCard
          item={item}
          key={key}
          analysisTexts={analysisTexts}
          locked={locked}
          modalRef={modalRef}
          onBoarding={onBoarding}
        />
      );
  }

  if (exercicesData) {
    return (
      <div
        style={{
          width: '95%',
          margin: 'auto',
          marginTop: '5%',
        }}>
        {(() => {
          if (exercicesData.length === 0) {
            return <NoExercices />;
          } else
            return exercicesData?.map((item, index) => {
              return renderItem(item, index);
            });
        })()}
      </div>
    );
  }
}

export default ExerciceContainer;

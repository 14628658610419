import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import RunningAnalysisBottomCard from './RunningAnalysisBottomCard';

import CardThumbnail from './CardThumbnail';
import './styles/SuccessCard.css';
import PronationAnalysisBottomCard from './PronationAnalysisBottomCard';
import { Box, Button } from '@mui/material';
import CompareAnalysisButton from './CompareAnalysisButton';
import { RUNNING } from 'constant/constants';
import AnalysisOptionsButton from './AnalysisOptionsButton';

function SuccessCard({ analysis, comparedAnalysis, setComparedAnalysis, disabled = false }) {
  const [data, setData] = useState(null);
  const [analysisSelected, setAnalysisSelected] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const jsonData = JSON.parse(
      localStorage.getItem(analysis?.data?.uploadVideoId),
    );
    if (jsonData) {
      setData(jsonData);
    }
  }, [analysis?.data?.uploadVideoId]);

  useEffect(() => {
    if (!comparedAnalysis) {
      setAnalysisSelected(false);
    }
  }, [comparedAnalysis]);

  const handleButtonClick = e => {
    if (analysis?.analysis_type !== RUNNING || analysis?.data?.locked === true)
      return;
    setComparedAnalysis(prev => {
      const newArr = [...prev];
      const analysisData = {
        analysisData: data,
        analysis: analysis,
      };
      const index = newArr.findIndex(
        item =>
          item.analysis?.data?.uploadVideoId ===
          analysisData?.analysis?.data?.uploadVideoId,
      );

      if (index !== -1) {
        newArr.splice(index, 1);
        setAnalysisSelected(false);
      } else if (newArr.length < 2) {
        newArr.push(analysisData);
        setAnalysisSelected(true);
      }
      return newArr;
    });
  };

  function handleCardClicked() {
    if (comparedAnalysis) {
      handleButtonClick();
    } else {
      if (analysis?.id) {
        navigate(`/analysis/${analysis?.id}`, {
          state: { analysis: analysis, personalised: data?.personalised },
        });
      } else
        navigate(`/analysis/${analysis?.data?.uploadVideoId}`, {
          state: { analysis: analysis, personalised: data?.personalised },
        });
    }
  }

  if (!data) return <></>;
  return (
    <Box onClick={handleCardClicked} sx={styles.container} >
      <div className="success-card-container">
        {comparedAnalysis ? (
          <CompareAnalysisButton
            analysis={analysis}
            analysisSelected={analysisSelected}
          />
        ) : (
          <AnalysisOptionsButton analysis={analysis} disabled={disabled} />
        )}
        <CardThumbnail analysis={analysis} thumbnail={data?.thumbnail} />
        <div style={styles.personalisedContainer}>
          {analysis?.analysis_type === 'pronation' ? (
            <PronationAnalysisBottomCard
              analysisData={data}
              locked={analysis?.data?.locked}
            />
          ) : (
            <RunningAnalysisBottomCard
              analysisData={data}
              locked={analysis?.data?.locked}
            />
          )}
        </div>
      </div>
    </Box>
  );
}

export default SuccessCard;

const styles = {
  container: {
    position: 'relative',
    maxWidth: '35rem',
    textDecoration: 'none',
  },
  personalisedContainer: {
    height: '20%',
    borderBottomRightRadius: 30,
    borderBottomLeftRadius: 30,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
};

import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  listAnalysis: [],
  videoSelected: {},
  listAnalysisData: [],
  correctLocalStorageJsonVersion: 0,
};

export const analysisSlice = createSlice({
  name: 'analysis',
  initialState,
  reducers: {
    resetAnalysisStore: (state, action) => {
      state.listAnalysis = [];
      state.videoSelected = {};
      state.listAnalysisData = [];
    },
    setListAnalysis: (state, action) => {
      const listAnalysisCopy = action?.payload?.analysis;
      if (listAnalysisCopy?.indexOf('123456') > -1) {
        listAnalysisCopy[listAnalysisCopy?.indexOf('123456')] =
          'fb97807a-17c2-4304-8d33-ce876ebd5254';
      }
      state.listAnalysis = listAnalysisCopy;
    },
    setVideoSelected: (state, action) => {
      state.videoSelected = action?.payload;
    },

    updateListAnalysisData: (state, action) => {
      state.listAnalysisData = action?.payload;
    },

    updateListAnalysisDataItem: (state, action) => {
      const listAnalysisDataCopy = [...state.listAnalysisData];
      const index = listAnalysisDataCopy.findIndex(
        item =>
          item?.data?.uploadVideoId === action?.payload?.data.uploadVideoId,
      );
      if (index > -1) {
        listAnalysisDataCopy[index] = action?.payload;
        state.listAnalysisData = listAnalysisDataCopy;
      }
    },

    addItemToListAnalysisData: (state, action) => {
      const listAnalysisDataCopy = [...state.listAnalysisData];
      listAnalysisDataCopy.push(action?.payload);
      state.listAnalysisData = listAnalysisDataCopy;
    },

    removeItemFromListAnalysisData: (state, action) => {
      const listAnalysisDataCopy = [...state.listAnalysisData];
      const index = listAnalysisDataCopy.findIndex(
        item => item.data.uploadVideoId === action?.payload?.data.uploadVideoId,
      );
      if (index > -1) {
        listAnalysisDataCopy.splice(index, 1);
        state.listAnalysisData = listAnalysisDataCopy;
      }
    },

    updateCorrectLocalStorageJsonVersion(state, action) {
      state.correctLocalStorageJsonVersion = action?.payload;
    }
  },
});

export const {
  setListAnalysis,
  setVideoSelected,
  updateListAnalysisData,
  removeItemFromListAnalysisData,
  addItemToListAnalysisData,
  updateListAnalysisDataItem,
  resetAnalysisStore,
  updateCorrectLocalStorageJsonVersion,
} = analysisSlice.actions;

export const analysisListState = state => state.analysis.listAnalysis;
export const analysisListData = state => state.analysis.listAnalysisData;
export const correctLocalStorageJsonVersion = state => state.analysis.correctLocalStorageJsonVersion;

export default analysisSlice.reducer;

import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';

import TextComponent from 'components/atoms/TextComponent';
import OnBoardingBottomArrow from 'screen/auth/registration/onBoarding/components/OnBoardingBottomArrow';

import { onBoardingTranslate, translate } from 'services/remoteConfig';

import {
  BODY_1,
  BOLD,
  COACH,
  H3,
  H4,
  REGISTRATION_DATA,
} from 'constant/constants';

import { createTextObjectReusable } from 'utils/utils';

import { colors } from 'themes';
import { mpTrack } from 'services/mixpanel';
import { ReactComponent as MoreIcon } from 'assets/svg/more.svg';

import './styles/OnBoardingCoachShare.css';


function OnBoardingCoachShare() {
  const navigate = useNavigate();
  const userType = JSON.parse(
    localStorage.getItem(REGISTRATION_DATA),
  )?.userType;

  useEffect(() => {
    userType === COACH
      ? mpTrack('Web App OnBoarding Coach Share Analysis Screen Viewed')
      : mpTrack('Web App OnBoarding Profesional Share Analysis Screen Viewed');
  }, []);

  function handleArrowClicked() {
    if (userType === COACH) {
      navigate('/registration/coach/treadmill');
    } else {
      navigate('/registration/good-video-tutorial');
    }
  }

  function handlePreviousArrowClicked() {
    navigate('/registration/coach/comments', {
      state: userType === COACH ? { value: 3 } : { value: 2 },
    });
  }

  return (
    <div style={styles.container}>
      <div style={styles.textContainer}>
        <TextComponent
          text={translate('Share')}
          variant={H4}
          weight={BOLD}
          sx={{ textAlign: 'center' }}
        />
      </div>
      <div style={styles.cardContainer}>
        <div
          style={{
            ...styles.shareCard,
            ...{
              backgroundImage: `url(${require('assets/images/shareAnalysis.jpg')})`,
            },
          }}>


          <div className="rotating-border-container">
            <div className="rotating-border"></div>
            <div className="inner-content">
              <MoreIcon style={{ height: 30, width: 30 }} />
            </div>
          </div>
        </div>

      </div>
      <TextComponent
        multipleText={createTextObjectReusable(
          onBoardingTranslate('ShareSubTitle'),
          {
            variant: H4,
          },
          {
            variant: H4,
            style: { color: colors.SECONDARY },
          },
        )}
        variant={H3}
        weight={BOLD}
        center={true}
      />
      <TextComponent
        text={onBoardingTranslate('ShareWithLogo')}
        variant={BODY_1}
        sx={{
          textAlign: 'center',
          marginTop: 2,
          color: colors.WHITE_OPACITY_60,
        }}
      />
      <OnBoardingBottomArrow onPress={handleArrowClicked} />
      <OnBoardingBottomArrow
        previous={true}
        onPress={handlePreviousArrowClicked}
      />
    </div>
  );
}

const styles = {
  cardContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '60vh',
  },
  container: {
    paddingTop: '5vh',
  },
  fingerAnimation: {
    float: 'right',
    marginTop: '5vh',
    width: '10%',
  },
  shareCard: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    minHeight: '50vh',
    width: '70vh',
    position: 'relative',
  },
  textContainer: {
    margin: 'auto',
    width: '60%',
  },
};
export default OnBoardingCoachShare;

import TextComponent from 'components/atoms/TextComponent';
import { BODY_2, H3, H6, MEDIUM } from 'constant/constants';
import React from 'react'
import { score_colors } from 'screen/analysis/AnalysisListCard/constants/analysisListConstants';
import { translate } from 'services/remoteConfig';
import ScoreTextWithBackground from '../../CardComponent/components/ScoreTextWithBackground';

function OverstrideContent({ overstrideData }) {

  if ((!overstrideData?.score && !overstrideData?.mean_angle) || overstrideData.score === 'score_good')
    return <></>;
  return (
    <div style={{
      alignSelf: 'center',
      marginTop: 20,
      display: 'flex',
      flexDirection: 'column',
      width: '85%',
    }}>
      <div style={{ display: 'flex', marginTop: 15, marginBottom: 20, }}>
        <TextComponent
          text={parseInt(overstrideData?.mean_angle) + '°'}
          variant={H3}
          sx={{
            color: score_colors[overstrideData?.score]?.stroke,
          }}
        />
        <div style={{ marginLeft: 20, }}>
          <TextComponent
            text={'Overstride'}
            weight={MEDIUM}
            variant={H6}
          />
          <div>
            <ScoreTextWithBackground scoreNumber={overstrideData?.score} />
          </div>
        </div>
      </div>
      <img
        src={
          overstrideData?.score === 'score_okay'
            ? require('assets/images/overstride_okay.png')
            : require('assets/images/overstride_bad.png')
        }
        style={{ width: '50%', alignSelf: 'center', marginBottom: 30, }}
        resizeMode="contain"
        alt={'overstride'}
      />

      <TextComponent
        text={
          overstrideData?.score === 'score_okay'
            ? translate('OverstrideOkay')
            : translate('OverstrideBad')
        }
        variant={BODY_2}
        style={{}}
      />
    </div>
  )
}
export default OverstrideContent
import React from 'react';
import { Grid } from '@mui/material';

import GraphPanel from './analysisContent/components/GraphPanel';
import { ANALYSIS, DISTANCE_METRIC_FT, GRAPH, METRICS, STYLE } from 'constant/constants';
import MetricsCardContent from './MetricsCardContent';
import AnalysisCardContent from './AnalysisCardContent';
import RunningStylePanel from './analysisContent/components/RunningStylePanel';
import { useSelector } from 'react-redux';
import { mToftIn } from 'utils/converter';

function AnalysisContentSelector({
  data,
  panel,
  setAnalysisData,
  overstrideData,
  version,
  prioritySegments,
  bestSegment,
  locked,
  modalRef,
  videoOrientation,
}) {

  const unityHeight = useSelector(state => state?.unity?.height);

  function dataSelector() {
    switch (panel) {
      case ANALYSIS:
        return data?.map((analysis, index) => {
          const findSegmentPosition = (() => {
            if (!prioritySegments) return -1;
            for (let i = 0; i < prioritySegments?.length; i++) {
              if (prioritySegments[i].segment === analysis?.type) {
                return i + 1;
              }
            }
            return -1; // Return -1 if the segment key is not found in the array
          })();
          return (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <AnalysisCardContent
                cardData={analysis}
                setAnalysisData={setAnalysisData}
                overstrideData={overstrideData}
                locked={
                  locked === true &&
                  findSegmentPosition !== 3 &&
                  bestSegment !== analysis?.type
                }
                modalRef={modalRef}
              />
            </Grid>
          );
        });
      case STYLE:
        return (
          <Grid item xs={12}>
            <RunningStylePanel
              data={data}
              version={version}
              locked={locked}
              modalRef={modalRef}
            />
          </Grid>
        );
      case METRICS:
        return Object?.keys(data)?.map((item, key) => {
          const cardData = (item === 'stride_length' || item === 'step_length') && unityHeight === DISTANCE_METRIC_FT ? { metricKey: item, value: mToftIn(data[item]) } : { metricKey: item, value: data[item] };
          return (
            <Grid item xs={12} sm={6} md={4} key={key}>
              <MetricsCardContent
                cardData={cardData}
                setAnalysisData={setAnalysisData}
                locked={locked}
                modalRef={modalRef}
              />
            </Grid>
          );
        });
      case GRAPH:
        return (
          <Grid item xs={12}>
            <GraphPanel graphData={data} videoOrientation={videoOrientation} />
          </Grid>
        );
      default:
        return null;
    }
  }

  return (
    <Grid
      container
      spacing={1}
      sx={{
        marginBottom: 4,
        marginTop: 2,
      }}>
      {dataSelector()}
    </Grid>
  );
}

export default AnalysisContentSelector;

import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    Font,
} from '@react-pdf/renderer';

import PdfSegments from './components/PdfSegments';
import PdfInfos from './components/PdfInfos';
import PdfRunningStyles from './components/PdfRunningStyles';
import PdfGraph from './components/PdfGraph';
import PdfMetrics from './components/PdfMetrics';
import PdfFoot from './components/PdfFoot';

import { translate, USER_LOCALE } from 'services/remoteConfig';

import font from 'assets/fonts/NotoSansSC/NotoSansSC-Regular.otf';
import { colors } from 'themes';
import { getAnalysisDataWithPanel } from 'services/analysis';
import { STYLE } from 'constant/constants';

export const PDF = ({
    data,
    jsonData,
    brandLogo,
    averageScore,
    analysisDB,
    itemsToDisplay,
    foot,
    heightUnity,
    jointAnglesData,
}) => {
    if (USER_LOCALE === 'zh')
        Font.register({
            family: 'NotoSansSC',
            format: 'truetype',
            src: font,
        });

    const running_styles = getAnalysisDataWithPanel(jsonData, STYLE).running_styles;


    let sectionIndex = 1;

    return (
        <Document>
            <Page
                style={[
                    styles.body,
                    USER_LOCALE === 'zh' ? { fontFamily: 'NotoSansSC' } : null,
                ]}>
                <PdfInfos averageScore={averageScore} analysisDB={analysisDB} jsonData={jsonData} brandLogo={brandLogo} />
                {(data?.length > 0 || foot) && (
                    <>
                        <Text style={styles.subtitle}>
                            {sectionIndex++} {translate('Analysis')}
                        </Text>
                        <PdfSegments data={data} />

                        {foot && (
                            <PdfFoot footStrikePattern={jsonData?.analysis_content?.foot?.foot_strike_pattern} foot={foot} overstrideValue={jsonData?.overstride?.mean_angle?.toFixed(0)} />
                        )}
                    </>
                )}
                {itemsToDisplay[1]?.Metrics?.length > 0 && (
                    <>
                        <Text style={styles.subtitle}>
                            {sectionIndex++} {translate('Metrics')}
                        </Text>
                        <PdfMetrics metrics={itemsToDisplay[1]?.Metrics} jsonData={jsonData} heightUnity={heightUnity} />
                    </>
                )}
                {itemsToDisplay[2]?.JointAngles?.includes('joint_angles') && (
                    <View wrap={false}>
                        <Text style={styles.subtitle}>
                            {sectionIndex++} {translate('JointAngles')}
                        </Text>
                        <PdfGraph jointAnglesData={jointAnglesData} />
                    </View>
                )}
                {itemsToDisplay[3]?.RunningStyle?.includes('running_styles') && (
                    <View wrap={false}>
                        <Text style={styles.subtitle}>
                            {sectionIndex++} {translate('RunningStyleTitle')}
                            {running_styles?.title}
                        </Text>
                        <PdfRunningStyles running_styles={running_styles} />
                    </View>
                )}
                {itemsToDisplay[4]?.Comments && (
                    <View wrap={false}>
                        <Text style={styles.subtitle}>
                            {sectionIndex++} {translate('Notes')}
                        </Text>
                        <View style={styles.section}>
                            <Text style={styles.text}>{itemsToDisplay[4]?.Comments}</Text>
                        </View>
                    </View>
                )}
            </Page>
        </Document>
    );
};

const styles = StyleSheet.create({
    body: {
        paddingHorizontal: 40,
        paddingVertical: 20,
    },
    text: {
        fontSize: 12,
        marginBottom: 10,
    },
    subtitle: {
        color: colors.PRIMARY,
        fontSize: 20,
        borderBottomWidth: 1,
        borderBottomColor: colors.PRIMARY,
        borderLeftWidth: 1,
        borderLeftColor: colors.PRIMARY,
        marginBottom: 10,
        padding: 5,
    },
    section: {
        padding: 10,
        marginBottom: 10,
    },

});

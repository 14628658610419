import {GREEN} from 'constant/constants';

const colors = {
  //#38393C
  //#282829

  PRIMARY: '#2E318E',
  PRIMARY_OPACITY_10: 'rgba(46, 49, 142, 0.10)',
  PRIMARY_OPACITY_15: 'rgba(46, 49, 142, 0.15)',
  SECONDARY: '#FF681D',
  SECONDARY_OPACITY_10: 'rgba(255, 104, 29, 0.10)',
  BLACK: '#000',
  WHITE: '#fff',
  ERROR: '#F4354B',
  APP_BACKGROUND: '#0F0F0F',
  BACKGROUND: '#0D0D0D',
  CARD_BACKGROUND: '#1D1D1D',
  SELECTED: '#38393C',
  HOVER: '#282829',
  SETTINGS_BACKGROUND: '#EAEAEA',
  SETTINGS_BUTTON_BACKGROUND: '#F5F5F5',
  SETTINGS_SVG_COLOR: '#797A95',
  SETTINGS_TEXT_BUTTON: '#3C3C3C',
  LIGHT_GRAY: '#BBB',
  VERY_LIGHT_GRAY: '#E1E1E1',
  DARK_GRAY: '#78798F',
  CLICKABLE: '#F0F0FA',
  IMAGE_BACKGROUND: '#C4C4C4',
  PICKER_BACKGROUND: '#DFDFFF',
  INFORMATIVE_BACKGROUND: '#DBDCFF',
  GREEN: '#78BD32',
  GREEN_FLASH: '#7BE014',
  GREEN_OPACITY_04: 'rgba(120, 189, 50, 0.04)',
  GREEN_OPACITY_10: 'rgba(120, 189, 50, 0.1)',
  GREEN_OPACITY_15: 'rgba(120, 189, 50, 0.15)',
  GREEN_OPACITY_2O: 'rgba(120, 189, 50, 0.2)',
  GREEN_OPACITY_4O: 'rgba(120, 189, 50, 0.4)',
  GREEN_OPACITY_5O: 'rgba(120, 189, 50, 0.5)',
  GREEN_OPACITY_6O: 'rgba(120, 189, 50, 0.6)',
  GREEN_OPACITY_7O: 'rgba(120, 189, 50, 0.7)',
  GREEN_VALID: '#40BF8E',
  GREEN_LIGHT: '#00FF9C',
  BLACK_OPACITY_04: 'rgba(0, 0, 0, 0.04)',
  BLACK_OPACITY_10: 'rgba(0, 0, 0, 0.1)',
  BLACK_OPACITY_16: 'rgba(0, 0, 0, 0.16)',
  BLACK_OPACITY_20: 'rgba(0, 0, 0, 0.2)',
  BLACK_OPACITY_25: 'rgba(0, 0, 0, 0.25)',
  BLACK_OPACITY_30: 'rgba(0, 0, 0, 0.3)',
  BLACK_OPACITY_40: 'rgba(0, 0, 0, 0.4)',
  BLACK_OPACITY_50: 'rgba(0, 0, 0, 0.5)',
  BLACK_OPACITY_60: 'rgba(0, 0, 0, 0.6)',
  BLACK_OPACITY_70: 'rgba(0, 0, 0, 0.7)',
  BLACK_OPACITY_80: 'rgba(0, 0, 0, 0.8)',
  BLACK_OPACITY_90: 'rgba(0, 0, 0, 0.9)',
  WHITE_OPACITY_04: 'rgba(255, 255, 255, 0.04)',
  WHITE_OPACITY_06: 'rgba(255, 255, 255, 0.06)',
  WHITE_OPACITY_10: 'rgba(255, 255, 255, 0.1)',
  WHITE_OPACITY_20: 'rgba(255, 255, 255, 0.2)',
  WHITE_OPACITY_30: 'rgba(255, 255, 255, 0.3)',
  WHITE_OPACITY_40: 'rgba(255, 255, 255, 0.4)',
  WHITE_OPACITY_50: 'rgba(255, 255, 255, 0.5)',
  WHITE_OPACITY_60: 'rgba(255, 255, 255, 0.6)',
  WHITE_OPACITY_70: 'rgba(255, 255, 255, 0.7)',
  WHITE_OPACITY_80: 'rgba(255, 255, 255, 0.8)',
  TEXT: '#000000',
  TEXT_WHITE: '#ffffff',
  TEXT_LIGHT: '#9F9F9F',
  TEXT_GRAY: '#737373',
  RED: '#EF1B29',
  RED_OPACITY15: '#EF1B2926',
  VALID: '#32CD32',
  VALID_IMAGE: '#40BF8E',
  GREEN_VALID_BG: '#D1FFED',
  OVERLAY_COLOR: '#6A72FB50',
  OVERLAY_COLOR_2: '#00000044',
  BOTTOM_TAB_BAR: '#E7E8F4',
  ANGLE_NAME: '#9B51E0',
  TRANSPARENT: 'transparent',
  BACKGROUND_REPORT_ITEM: '#E7E8F4',
  HEAD_BACKGROUND: '#D7D9FB',
  CLICK_HERE: '#333333',
  TEXT_REPORT: '#6A72FB',
  HEAD_REPORT_BACKGROUND: '#D7D9FB',
  BACK_REPORT_BACKGROUND: '#FDC70877',
  ARMS_REPORT_BACKGROUND: '#07F05655',
  FRONT_LEG_REPORT_BACKGROUND: '#FD080844',
  BACK_LEG_REPORT_BACKGROUND: '#FD080844',
  DISABLED: '#F2F2F2',
  SEARCH_BAR_BACKGROuND: '#F0F0FA',
  SHADOW_COLOR: '#171717',
  opacityFilter: 'rgba(52, 52, 52, 0.35)',
  OCHY_BLUE: '#2A35EE',
  ORANGE1: '#FFA070',
  ORANGE2: '#FF681D',
  OPACITYBACKGROUND: 'rgba(0,0,0,0.5)',
  SELECTOR_BACKGROUND: '#EEEFFA',
  UNSELECTED_FOND: '#B7B8D6',
  VERTICAL_LINE: '#686BDE',
  HEADER_BACKGROUND: '#0A0E0F',
  YELLOW: '#F5D000',
  YELLOW_OPACITY_10: 'rgba(245, 208, 0, 0.10)',
  YELLOW_OPACITY_15: 'rgba(245, 208, 0, 0.15)',
};

export default colors;

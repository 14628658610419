import TextComponent from "components/atoms/TextComponent";
import { BODY_2 } from "constant/constants";
import React from "react";
import RoundedScoreMeter from "screen/analysis/AnalysisListCard/components/RoundedScoreMeter";
import ScoreTextWithBackground from "./components/ScoreTextWithBackground";



function AnalysisFootCardTopContent({ cardData, overstrideData }) {

    return (
        <div style={{ display: 'flex', position: 'relative' }}>
            <div
                style={{
                    display: 'flex',
                    float: 'left',
                }}>
                <RoundedScoreMeter
                    scoreNumber={cardData?.score_number}
                    scoreText={cardData?.score}
                    item={cardData?.type}
                    size={80}
                />
            </div>
            {overstrideData?.score !== 'score_good' ? (
                <div style={{ marginTop: '3%', marginLeft: 5 }}>
                    <div
                        style={{
                            display: 'flex',
                            paddingTop: '2%',
                            alignItems: 'flex-end',
                            marginBottom: 5,
                        }}>
                        {cardData?.score_number === -1 ? null : (
                            <TextComponent
                                text={(cardData?.score_number * 100)?.toFixed(0) + '%'}
                                sx={{ margin: 0, fontSize: '1rem' }}
                            />
                        )}
                        <TextComponent
                            text={!cardData?.phases ? 'overstride' : 'score'}
                            variant={BODY_2}
                            sx={{
                                marginLeft: 0.7,
                                marginBottom: 0.3,
                                color: '#737373',
                            }}
                        />
                    </div>
                    <ScoreTextWithBackground
                        scoreNumber={overstrideData?.score}
                    />
                </div>
            ) : null}
        </div>
    );


}

export default AnalysisFootCardTopContent;
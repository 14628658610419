import React from "react";
import TextComponent from "components/atoms/TextComponent";
import OverstrideContent from "./OverstrideContent";
import { BODY_2, BOLD, H6 } from "constant/constants";

function BodyContainerContentFoot({ locked, displayData, overstrideData }) {

    return (
        <div
            style={{
                ...styles.sidePan,
                ...(locked ? styles.lockedContainer : null),
            }}>
            <div style={styles.container}>
                <TextComponent
                    text={displayData?.text1}
                    weight={BOLD}
                    sx={{ marginBottom: 2 }}
                    variant={H6}
                />
                <img src={displayData?.image} alt="ankle" style={styles.footAsset} />
                <TextComponent text={displayData?.text2} variant={BODY_2} />
            </div>
            <div style={styles.dashedLine} />
            <OverstrideContent overstrideData={overstrideData} />
        </div>
    )
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 40,
        width: '85%',
    },
    dashedLine: {
        border: '1px dashed rgba(255, 255, 255, 0.1)',
        marginBottom: 40,
        marginTop: 40,
        width: '95%',
    },
    footAsset: {
        alignSelf: 'center',
        marginBottom: 30,
        width: '70%',
    },
    lockedContainer: {
        filter: 'blur(30px)',
        pointerEvents: 'none',
        userSelect: 'none',
    },
    sidePan: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
};


export default BodyContainerContentFoot;
import React, { useRef, useState } from "react";

import { translate } from "services/remoteConfig";

import "./styles/DragNDrop.css";
import SnackBarComponent from "components/atoms/SnackBarComponent";
import { ANALYSISTYPE_RUNNING_TREADMILL, ERROR, ORANGE } from "constant/constants";
import ButtonComponent from "components/atoms/ButtonComponent";
import TextComponent from "components/atoms/TextComponent";
import { mpTrack } from "services/mixpanel";

const VALID_FORMATS = ["video/mp4", "video/quicktime"];

function DragAndDrop({ callback, inputRef, source, setSource, analysisType }) {
  const [dragActive, setDragActive] = useState(false);
  const [error, setError] = useState(undefined);
  const [open, setOpen] = useState(false);

  const videoRef = useRef();

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const specs = {
    running: {
      landscape: true,
      portrait: false,
      maxDuration: 10,
      minDuration: 2,
    },
    pronation: {
      landscape: false,
      portrait: true,
      maxDuration: 10,
      minDuration: 1,
    },
    running_treadmill: {
      landscape: false,
      portrait: true,
      maxDuration: 10,
      minDuration: 2,
    },
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e?.dataTransfer?.files && e?.dataTransfer?.files[0]) {
      const file = e.dataTransfer.files[0];
      if (!VALID_FORMATS.includes(file?.type)) {
        setError(translate("FileNotCorrectFormat"));
        setOpen(true);
        setSource(undefined);
        return;
      }
      const url = URL.createObjectURL(file);
      setError("");
      setOpen(false);
      setSource(url);
      if (file.size > 66 * 1024000) {
        setError(translate("FileSizeTooBig"));
        return;
      }
      mpTrack('Web App Video Dragged and Dropped')
      callback(url);
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e?.target?.files && e?.target?.files[0]) {
      const file = e?.target?.files[0];
      const url = URL.createObjectURL(file);
      if (file?.size > 66 * 1024000) {
        setError(translate("FileSizeTooBig"));
        setOpen(true);
        setSource(undefined);
        inputRef.current.value = null;
        return;
      }

      mpTrack('Web App Video Chosen from input')
      setSource(url);
      callback(url);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  function handleMetadata(event) {
    if (videoRef?.current?.duration > specs[analysisType]?.maxDuration) {
      setError(translate("VideoTooLongError"));
      setOpen(true);
      setSource(undefined);
    }
    if (videoRef?.current?.videoWidth < 720) {
      setError(translate("LowQualityVideoError"));
      setOpen(true);
      setSource(undefined);
    }
    if (specs[analysisType]?.landscape === true &&
      videoRef?.current?.videoHeight > videoRef?.current?.videoWidth &&
      analysisType !== ANALYSISTYPE_RUNNING_TREADMILL) {
      setError(translate('VideoNotInLandscape'));
      setOpen(true);
      setSource(undefined);
    }
    if (
      specs[analysisType]?.portrait === true &&
      videoRef?.current?.videoWidth > videoRef?.current?.videoHeight &&
      analysisType !== ANALYSISTYPE_RUNNING_TREADMILL
    ) {
      setError(translate('VideoNotInPortrait'));
      setOpen(true);
      setSource(undefined);
    }
  }

  return (
    <form
      id="form-file-upload"
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
    >
      <input
        ref={inputRef}
        type="file"
        accept=".mp4 ,.mov"
        id="input-file-upload"
        onChange={handleChange}
      />
      <label
        id="label-file-upload"
        htmlFor="input-file-upload"
        className={dragActive ? "drag-active" : ""}
      >
        {!source ? (
          <div
            style={{
              position: "absolute",
              height: "90%",
              width: "95%",
              border: "2px dashed rgba(255, 255, 255, 0.4)",
              borderRadius: "10px",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <TextComponent
              variant={"h6"}
              text={
                translate("DragNDrop")
              }
              sx={{ marginBottom: 1, textAlign: "center" }}
            />
            <TextComponent
              variant={"body2"}
              text={translate("DragNDropSubtitle")}
              sx={{ marginBottom: 5, color: "lightgray" }}
            />
            <ButtonComponent
              type={ORANGE}
              title={translate("ChooseVideo")}
              onClick={() => {
                inputRef?.current?.click();
              }}
            />
          </div>
        ) : null}

        <SnackBarComponent
          message={error}
          open={open}
          handleClose={handleClose}
          serverity={ERROR}
        />
        {source && (
          <video
            ref={videoRef}
            className="video-input-video"
            controls
            src={source}
            onLoadedMetadata={handleMetadata}
          />
        )}
      </label>
      {dragActive && (
        <div
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
    </form>
  );
}

export default DragAndDrop;

import React from "react";
import TextComponent from "components/atoms/TextComponent";
import { BODY_1, BOLD, H5 } from "constant/constants";
import { colors } from "themes";

function AnalysisRightPanelGraph({ displayData }) {
  return (
    <div>
      <TextComponent
        text={displayData?.graphTitle}
        variant={H5}
        sx={{ marginBottom: 2, marginTop: 2, marginLeft: 1 }}
      />
      <TextComponent
        text={displayData?.graphData}
        variant={BODY_1}
        sx={{ lineHeight: 1.75, marginLeft: 1, marginBottom: '4vh' }}
      />

      {displayData?.jointAngles?.map((data, index) => (
        <div key={index} style={{ marginTop: '2vh' }}>
          <TextComponent
            text={data?.title}
            variant={H5}
            weight={BOLD}
            sx={{ marginLeft: 1, color: colors.SECONDARY }}
          />
          <TextComponent
            text={data?.explanation}
            variant={BODY_1}
            sx={{ lineHeight: 1.75, marginLeft: 1 }}
          />
        </div>
      ))}

    </div>
  );
}

export default AnalysisRightPanelGraph;

import React, {useEffect} from 'react';

import {Grid} from '@mui/material';

import TextComponent from 'components/atoms/TextComponent';
import MetricsCardContent from 'screen/analysis/AnalysisViewDetailsScreen/components/MetricsCardContent';

import {onBoardingTranslate} from 'services/remoteConfig';

import {createTextObjectReusable} from 'utils/utils';

import {H5} from 'constant/constants';
import {metricData} from 'screen/auth/registration/onBoarding/constants/onBoardingButtonData';

import {colors} from 'themes';

function OnBoardingCoachMetrics({displayData, setDisplayData}) {
  return (
    <>
      <div style={{marginTop: 20}} />
      <TextComponent
        multipleText={createTextObjectReusable(
          onBoardingTranslate('MetricsHeadline'),
          {
            variant: H5,
          },
          {
            variant: H5,
            style: {textAlign: 'center', color: colors.SECONDARY},
          },
        )}
        center={true}
      />
      <Grid container spacing={2} style={{marginTop: 20}}>
        {metricData?.map((data, index) => (
          <Grid item xs={12} sm={10} md={8} lg={6}>
            <MetricsCardContent
              cardData={data}
              key={index}
              setAnalysisData={() => {
                setDisplayData(data);
              }}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default OnBoardingCoachMetrics;

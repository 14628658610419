import React, {useEffect} from 'react';

import {useNavigate} from 'react-router-dom';

import TextComponent from 'components/atoms/TextComponent';

import {translate} from 'services/remoteConfig';
import {mpTrack} from 'services/mixpanel';

import {H4, H5} from 'constant/constants';

import {EXERCISE_VIDEO_URL} from 'constant/contentConstant';
import OnBoardingAnalysisContainer from './components/OnBoardingAnalysisContainer';

function OnBoardingExercises() {
  const navigate = useNavigate();

  useEffect(() => {
    mpTrack('Web App OnBoarding Exercises List Screen Viewed');
  }, []);

  function handleArrowPressed() {
    navigate('/registration/athlete/compare');
  }

  function handleBackArrowPressed() {
    navigate('/registration/athlete/analyse', {
      state: {value: 1},
    });
  }

  return (
    <OnBoardingAnalysisContainer
      handleNextArrowClicked={handleArrowPressed}
      handlePreviousArrowClicked={handleBackArrowPressed}
      videoUri={EXERCISE_VIDEO_URL}
      videoStyle={{height: '90vh'}}>
      <div style={{marginTop: 10}}>
        <TextComponent
          text={translate('Train.athlete')}
          variant={H4}
          sx={{textAlign: 'center', marginTop: 2}}
        />
        <TextComponent
          text={translate('TrainOnboardingDesc')}
          variant={H5}
          sx={{marginTop: 3, marginLeft: 1}}
        />
      </div>
    </OnBoardingAnalysisContainer>
  );
}

const styles = {
  container: {
    display: 'flex',
    // justifyContent: 'center',
    margin: 'auto',
  },
  gridContainer: {
    flexGrow: 1,
    padding: 2,
    marginTop: 15,
  },
  videoContainer: {
    width: '80%',
    display: 'flex',
    height: '90vh',
  },
};

export default OnBoardingExercises;

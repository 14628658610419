import React, { useRef } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import TextComponent from 'components/atoms/TextComponent';
import VideoComponent from 'screen/analysis/AnalysisViewDetailsScreen/components/VideoComponent';
import VideoValidation from './VideoValidation';
import ModalInProgress from './components/ModalInProgress';

import { translate } from 'services/remoteConfig';

import { BODY_1, BODY_2, BOLD, H4, MEDIUM } from 'constant/constants';
import { uploadAnalysisTypeList } from 'constant/uploadContant';
import { colors } from 'themes';
import './components/styles/ChoseAnalysisType.css'; // Import the stylesheet
import { mpTrack } from 'services/mixpanel';

export default function ChoseAnalysisType({ startUpload }) {
  const analysisTypeList = uploadAnalysisTypeList;
  const navigate = useNavigate();
  const location = useLocation();
  const modalRef = useRef();

  if (location?.pathname?.includes('running_treadmill')) {
    return (
      <VideoValidation
        analysisType={'running_treadmill'}
        startUpload={startUpload}
      />
    );
  }
  if (location?.pathname?.includes('running')) {
    return (
      <VideoValidation analysisType={'running'} startUpload={startUpload} />
    );
  } else if (location?.pathname?.includes('pronation')) {
    return (
      <VideoValidation analysisType={'pronation'} startUpload={startUpload} />
    );
  }

  return (
    <div style={{ display: 'flex' }}>
      {Object.entries(analysisTypeList)?.map(
        ([categoryName, categoryDetails]) => (
          <div key={categoryName} style={styles.category}>
            <TextComponent
              text={translate(categoryName)}
              variant={H4}
              weight={BOLD}
              sx={styles.categoryName}
            />
            <div style={styles.categoryDetails}>
              {categoryDetails?.types?.map(type => (
                <div
                  key={type.key}
                  className="cardStyle"
                  onClick={() => {
                    mpTrack(type?.key + 'Web App Analysis Button Clicked');
                    navigate(`/upload-video/${type?.key}`);
                    if (type?.key !== 'running' && type?.key !== 'pronation') {
                      modalRef.current.open(type?.key);
                    }
                  }}>
                  <TextComponent
                    text={translate(type.title)}
                    variant={BODY_1}
                    weight={BOLD}
                    sx={styles.cardTitle}
                  />
                  <TextComponent
                    text={translate(type?.desc)}
                    variant={BODY_2}
                    weight={MEDIUM}
                    sx={styles.typeDesc}
                  />
                  <div style={styles.videoContainer}>
                    <VideoComponent
                      video={type?.exempleVideo}
                      controls={false}
                      speed={1}
                      videoStyle={{ maxHeight: '35vh' }}
                    />
                    <TextComponent
                      text={translate('AnalysisTypeExempleVideoDesc')}
                      weight={MEDIUM}
                      sx={styles.videoDesc}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        ),
      )}
      <ModalInProgress ref={modalRef} />
    </div>
  );
}

const styles = {
  cardTitle: {
    marginBottom: 2,
  },
  category: {
    margin: 10,
    marginBottom: 20,
    marginRight: '5vw',
  },
  categoryName: {
    marginBottom: 5,
  },
  categoryDetails: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
  },
  typeDesc: {
    color: colors.WHITE_OPACITY_70,
    marginBottom: 2,
  },

  videoContainer: {
    width: '100%',
    height: '100%',
    marginTop: 10,
    marginBottom: 10,
  },
  videoDesc: {
    color: colors.WHITE_OPACITY_70,
    marginLeft: '2.5%',
    fontSize: '10px',
  },
};

import FadinView from 'components/atoms/FadinView';
import TextComponent from 'components/atoms/TextComponent';
import React from 'react';
import OnBoardingBottomArrow from '../components/OnBoardingBottomArrow';
import { createTextObjectReusable } from 'utils/utils';
import { onBoardingTranslate, translate } from 'services/remoteConfig';
import { BODY_1, BOLD, H4, H5 } from 'constant/constants';
import { colors } from 'themes';
import HeightComponent from 'components/atoms/HeightComponent';
import WeightComponent from 'components/atoms/WeightComponent';
import OnBoardingAthleteCard from '../components/OnBoardingAthleteCard';
import { useNavigate } from 'react-router-dom';

function OnBoardingCoachAthletePresentation() {
  const navigate = useNavigate();

  function handleArrowPressed() {
    navigate('/registration/coach/analysis');
  }
  function handleBackArrowPressed() {
    navigate('/registration/coach/place-of-work');
  }

  return (
    <FadinView>
      <div style={styles.container}>
        <div style={styles.buttonContainer}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              height: '50vh',
              justifyContent: 'space-evenly',
            }}>
            <div style={{ width: '45%' }}>
              <OnBoardingAthleteCard title={'Perrine'} height={1.70} weight={60} />
            </div>
            <div style={{ width: '45%' }}>
              <OnBoardingAthleteCard title={'Mike'} height={1.83} weight={78} />
            </div>
            <div style={{ width: '45%' }}>
              <OnBoardingAthleteCard title={'Simon'} height={2.01} weight={82} />
            </div>
          </div>
        </div>
        <div style={styles.titleContainer}>
          <TextComponent
            text={translate('Organize')}
            variant={H4}
            sx={{ textAlign: 'center', marginBottom: 5 }}
          />
        </div>

        <TextComponent
          multipleText={createTextObjectReusable(
            onBoardingTranslate('AthleteCreationTitle'),
            {
              variant: H5,
              weight: BOLD,
            },
            {
              variant: H5,
              weight: BOLD,
              style: { textAlign: 'center', color: colors.SECONDARY },
            },
          )}
          center={true}
        />
      </div>
      <OnBoardingBottomArrow onPress={handleArrowPressed} />
      <OnBoardingBottomArrow previous={true} onPress={handleBackArrowPressed} />
    </FadinView>
  );
}
const styles = {
  buttonContainer: {
    height: '100%',
    justifyContent: 'space-evenly',
    paddingTop: '5%',
    width: '100%',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '4%',
    width: '100%',
  },
};

export default OnBoardingCoachAthletePresentation;

import React, { useImperativeHandle, useRef, useState } from "react";

import { Fade, Modal } from "@mui/material";

import { ReactComponent as MoreIcon } from 'assets/svg/more.svg';
import { ReactComponent as Arrow } from 'assets/svg/arrow.svg';
import { ReactComponent as Close } from "assets/svg/close.svg";
import AnalysisOptionsContent from "./AnalysisOptionsContent";

import { colors } from "themes";

function AnalysisOptionsButton({ analysis, disabled = false }) {
    const modalRef = useRef();
    const [modalContent, setModalContent] = useState(null);
    const [open, setOpen] = useState(false);

    useImperativeHandle(modalRef, () => ({
        close() {
            setOpen(false);
            setModalContent(null);
        },
    }));


    function handlePressed(event) {
        event.preventDefault();
        event.stopPropagation();
        setOpen(true);
    }

    if (analysis?.exemple || disabled) return <></>;

    return (
        <div
            className="share-icon-container"
            onClick={event => {
                handlePressed(event);
            }}
        >
            <MoreIcon style={{ height: '3vh', width: '3vh' }} />

            <Modal
                open={open}
                onClose={(e) => {
                    setOpen(false);
                    setModalContent(null);
                    e.stopPropagation();
                }}
                sx={styles.modal}
            >
                <Fade in={open}>
                    <div style={styles.modalContainer}>
                        <div style={styles.header}>
                            {modalContent !== null && (
                                <Arrow
                                    style={styles.arrowBack}
                                    onClick={() => setModalContent(null)}
                                />
                            )}
                            <Close
                                stroke="white"
                                style={styles.close}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setOpen(false);
                                }}
                            />
                        </div>
                        <AnalysisOptionsContent analysis={analysis} modalContent={modalContent} setModalContent={setModalContent} modalRef={modalRef} setOpen={setOpen} />
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}

const styles = {
    arrowBack: {
        height: 30,
        width: 30,
        transform: 'rotate(180deg)',
        cursor: 'pointer',
        position: 'absolute',
        left: 10
    },
    close: {
        height: 50,
        width: 50,
        cursor: 'pointer',
        position: 'absolute',
        right: 0
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: '10px 0',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalContainer: {
        width: '45vw',
        backgroundColor: colors.BACKGROUND,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        padding: '20px',
        borderRadius: '10px',
    },
};


export default AnalysisOptionsButton;

import React, { useRef, useState } from 'react';

import Lottie from 'lottie-react';
import html2canvas from 'html2canvas';

import TextInput from 'components/atoms/TextInput';
import TextComponent from 'components/atoms/TextComponent';
import PDFJointAnglesSelection from './PDFJointAnglesSelection';
import PDFCategoryData from './PDFCategoryData';
import GeneratePDF from 'screen/analysis/PdfExport/GeneratePDF';

import { mpTrack } from 'services/mixpanel';
import { translate } from 'services/remoteConfig';

import { BOLD, H4 } from 'constant/constants';
import { ANALYSIS_ITEMS } from 'constant/pdfConstants';

import successLottie from 'assets/animations/success.json';
import './styles/PDFSelectionList.css';

import { colors } from 'themes';
import { determineInvalidElementToExport } from 'utils/utils';


export default function PDFSelectionList({ data, jsonData, setOpen }) {
    const [success, setSuccess] = useState(false);
    const [step, setStep] = useState(0);


    const initializeSelection = () => {
        const initialSelection = {};

        ANALYSIS_ITEMS?.forEach(({ category, items }) => {
            if (!initialSelection[category]) {
                initialSelection[category] = [];
            }

            Object.keys(items)?.forEach(item => {
                if (determineInvalidElementToExport(jsonData, category, item)) return;

                initialSelection[category]?.push(item);
            });
        });

        return initialSelection;
    };


    const [selectedButtons, setSelectedButtons] = useState(initializeSelection());
    const [inputText, setInputText] = useState('');
    const [jointAnglesData, setJointAnglesData] = useState(null);


    const chartRef = useRef(null);

    async function exportJointAnglesToPDF() {
        mpTrack('Web App PDF export Joint Angles');
        const chartElement = chartRef?.current;
        const canvas = await html2canvas(chartElement);
        const chartImage = canvas?.toDataURL('image/png');
        return { data: jointAnglesData, chart: chartImage };
    }

    const handleInputChange = (event) => {
        mpTrack('Web App PDF Add Notes');
        setInputText(event?.target?.value);
    };


    if (jsonData?.json_version < 4) {
        return (
            <div style={styles.oldAnalysis}>
                <TextComponent text={translate('OldAnalysisPDF')} variant={H4} weight={BOLD} sx={{ textAlign: 'center' }} />
            </div>
        )
    }

    if (success)
        return (
            <div style={styles.success}>
                <TextComponent text={translate('ExportedPDF')} variant={H4} weight={BOLD} sx={styles.messageSend} />
                <Lottie
                    animationData={successLottie}
                    style={styles.lottie}
                    onLoopComplete={() => {
                        setSuccess(false);
                        setOpen(false)
                    }}
                />
            </div>
        )

    else
        return (
            <div className="container">

                {step === 0 && (
                    <>
                        <TextComponent text={translate('SelectElementToExport')} sx={{ marginBottom: '2vh', fontSize: '2vw' }} />
                        <PDFCategoryData selectedButtons={selectedButtons} setSelectedButtons={setSelectedButtons} jsonData={jsonData} />
                        <TextInput label={translate('AddNotes')} type={'longtext'} onChange={handleInputChange} />
                    </>
                )}

                {step === 1 && (
                    <PDFJointAnglesSelection jointAngles={jsonData?.joint_angles} direction={jsonData?.direction} setJointAnglesData={setJointAnglesData} chartRef={chartRef} />
                )}

                <GeneratePDF data={data} jsonData={jsonData} itemsToDisplay={[
                    { Segments: selectedButtons?.Segments },
                    { Metrics: selectedButtons?.Metrics },
                    { JointAngles: selectedButtons?.Other?.find(item => item === 'joint_angles') },
                    { RunningStyle: selectedButtons?.Other?.find(item => item === 'running_styles') },
                    { Comments: inputText }
                ]}
                    setSucces={setSuccess}
                    step={step}
                    setStep={setStep}
                    exportJointAnglesToPDF={exportJointAnglesToPDF}
                />
            </div>
        );
}

const styles = {
    lottie: {
        width: '20vw',
        height: '20vh',
        marginBottom: '10vh'
    },
    messageSend: {
        color: colors.WHITE,
        marginBottom: '10vh'
    },
    oldAnalysis: { height: '40vh', display: 'flex', alignItems: 'center', width: '95%' },
    success: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '10vh'
    },
};
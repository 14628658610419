import {colors, images} from 'themes';
import {translate} from 'services/remoteConfig';
import {isWoman} from './utils';
import {FORE_FOOT, MID_FOOT, REAR_FOOT} from 'constant/constants';

export function getHeadText(head, angle, version) {
  let color = colors.HEAD_REPORT_BACKGROUND;
  let image = images.ANALYSIS_HEAD_ICON;
  let text = '';
  let type = 'RightEar';
  let scoreNumber = 0;
  let versionFourData = {};

  if (head) {
    text = head?.texts;
    scoreNumber = head?.score_number;
    if (version > 3) {
      versionFourData.analysisKey = head?.asset_id;
      versionFourData.rangeGood = head?.range_good;
      versionFourData.rangeOkay = head?.range_okay;
      versionFourData.videos = head?.videos;
      versionFourData.recap = head?.texts?.recap;
      versionFourData.segmentedExplanation = head?.texts?.segmented_explanation;
    }
  }

  return prepareTexts(
    text,
    angle,
    color,
    image,
    type,
    isWoman() ? images.PERFECT_FEMALE_HEAD : images.PERFECT_MALE_HEAD,
    scoreNumber,
    versionFourData,
  );
}

export function getBackText(trunk, angle, version) {
  let color = colors.BACK_REPORT_BACKGROUND;
  let text = '';
  let image = images.ANALYSIS_BODY_BACK_ICON;
  let type = 'RightHip';
  let scoreNumber = 0;
  let versionFourData = {};

  if (trunk) {
    text = trunk?.texts;
    scoreNumber = trunk?.score_number;
    if (version > 3) {
      versionFourData.analysisKey = trunk?.asset_id;
      versionFourData.rangeGood = trunk?.range_good;
      versionFourData.rangeOkay = trunk?.range_okay;
      versionFourData.videos = trunk?.videos;
      versionFourData.recap = trunk?.texts?.recap;
      versionFourData.segmentedExplanation =
        trunk?.texts?.segmented_explanation;
    }
  }

  return prepareTexts(
    text,
    angle,
    color,
    image,
    type,
    isWoman() ? images.PERFECT_FEMALE_BACK : images.PERFECT_MALE_BACK,
    scoreNumber,
    versionFourData,
  );
}

export function getArmText(arm, angle, version) {
  let color = colors.ARMS_REPORT_BACKGROUND;
  let text = '';
  let image = images.ANALYSIS_ARM_ICON;
  let type = 'RightElbow';
  let scoreNumber = 0;
  let versionFourData = {};

  if (arm) {
    text = arm?.texts;
    scoreNumber = arm?.score_number;
    if (version > 3) {
      versionFourData.analysisKey = arm?.asset_id;
      versionFourData.rangeGood = arm?.range_good;
      versionFourData.rangeOkay = arm?.range_okay;
      versionFourData.videos = arm?.videos;
      versionFourData.recap = arm?.texts?.recap;
      versionFourData.segmentedExplanation = arm?.texts?.segmented_explanation;
    }
  }

  return prepareTexts(
    text,
    angle,
    color,
    image,
    type,
    isWoman() ? images.PERFECT_FEMALE_ARM : images.PERFECT_MALE_ARM,
    scoreNumber,
    versionFourData,
  );
}

export function getFootText(foot) {
  let text = '';
  let image;
  const enume = 'RightAnkle';

  if (foot) {
    text = foot?.texts;
    const footStrikePattern = foot?.foot_strike_pattern;
    if (footStrikePattern === REAR_FOOT) {
      image = images.REAR_FOOT;
    } else if (footStrikePattern === FORE_FOOT) {
      image = images.FORE_FOOT;
    } else if (footStrikePattern === MID_FOOT) {
      image = images.MID_FOOT;
    }
  }

  return {
    title: text?.title || null,
    text1: text?.position || null,
    text2: text?.foot_strike_explanation || null,
    image: image || null,
    score: text?.score || 0,
    footStrikePattern: foot?.foot_strike_pattern || null,
    overstrideTitle: text?.overstride_title || null,
    overstrideExplanation: text?.overstride_explanation || null,
    footStrikeExplanation: text?.foot_strike_explanation || null,
    phases: text?.phases || null,
    exercice: text?.exercice || null,
    score_number: foot?.score_number === 0 ? 0 : foot?.score_number || -1,
    asset_id: foot?.asset_id || null,
    rangeGood: foot?.range_good || null,
    rangeOkay: foot?.range_okay || null,
    type: enume,
  };
}

export function getKneeBackText(back_cycle, angle, version) {
  let color = colors.BACK_LEG_REPORT_BACKGROUND;
  let text = '';
  let image = images.ANALYSIS_BACK_LEG_ICON;
  let type = 'LeftKnee';
  let scoreNumber = 0;
  let versionFourData = {};
  if (back_cycle) {
    text = back_cycle?.texts;
    scoreNumber = back_cycle?.score_number;
    if (version > 3) {
      versionFourData.analysisKey = back_cycle?.asset_id;
      versionFourData.rangeGood = back_cycle?.range_good;
      versionFourData.rangeOkay = back_cycle?.range_okay;
      versionFourData.videos = back_cycle?.videos;
      versionFourData.recap = back_cycle?.texts?.recap;
      versionFourData.segmentedExplanation =
        back_cycle?.texts?.segmented_explanation;
    }
  }

  return prepareTexts(
    text,
    angle,
    color,
    image,
    type,
    isWoman()
      ? images.PERFECT_FEMALE_BACK_CYCLE
      : images.PERFECT_MALE_BACK_CYCLE,
    scoreNumber,
    versionFourData,
  );
}

export function getKneeFrontText(forward_cycle, angle, version) {
  let color = colors.FRONT_LEG_REPORT_BACKGROUND;
  let text = '';
  let image = images.ANALYSIS_FRONT_LEG_ICON;
  let type = 'RightKnee';
  let scoreNumber = 0;
  let versionFourData = {};
  if (forward_cycle) {
    text = forward_cycle?.texts;
    scoreNumber = forward_cycle?.score_number;
    if (version > 3) {
      versionFourData.analysisKey = forward_cycle?.asset_id;
      versionFourData.rangeGood = forward_cycle?.range_good;
      versionFourData.rangeOkay = forward_cycle?.range_okay;
      versionFourData.videos = forward_cycle?.videos;
      versionFourData.recap = forward_cycle?.texts?.recap;
      versionFourData.segmentedExplanation =
        forward_cycle?.texts?.segmented_explanation;
    }
  }
  return prepareTexts(
    text,
    angle,
    color,
    image,
    type,
    isWoman()
      ? images.PERFECT_FEMALE_FRONT_CYCLE
      : images.PERFECT_MALE_FRONT_CYCLE,
    scoreNumber,
    versionFourData,
  );
}

export function prepareTexts(
  text,
  angle,
  color,
  image,
  type,
  idealImage,
  scoreNumber,
  versionFourData,
) {
  return {
    angle: angle,
    title: text?.title,
    subtitile1: 'Position',
    text1: text?.position,
    subtitile2: translate('Explanation'),
    text2: text?.explanation,
    subtitile3: translate('Exercice'),
    exercice: text?.exercice,
    advice: text?.advice,
    backgroundColor: color,
    image: image,
    score: text?.score,
    score_number: scoreNumber,
    type: type,
    idealImage: idealImage,
    rangeGood: versionFourData?.rangeGood,
    rangeOkay: versionFourData?.rangeOkay,
    videos: versionFourData?.videos,
    recap: versionFourData?.recap,
    segmentedExplanation: versionFourData?.segmentedExplanation,
    analysisKey: versionFourData.analysisKey,
  };
}

import React, {useEffect} from 'react';

import VideoComponent from '../VideoComponent';
import PronationMetricsPanel from './PronationMetricsPanel';
import {mpTrack} from 'services/mixpanel';
import TextComponent from 'components/atoms/TextComponent';
import {translate} from 'services/remoteConfig';
import {H6} from 'constant/constants';
import {ReactComponent as Lock} from 'assets/svg/lock.svg';
import {colors} from 'themes';

export default function PronationAnalysis({data, locked, modalRef}) {
  useEffect(() => {
    mpTrack('Web App Pronnation Analysis Viewed');
  }, []);

  return (
    <div style={styles.container}>
      <div style={styles.videoContainer}>
        <VideoComponent video={data?.videoUrl} />
      </div>
      <div style={styles.metricsContainer}>
        <div
          style={{
            ...styles.metricsSubContainer,
            ...(locked ? styles.blur : null),
          }}>
          <PronationMetricsPanel
            posture={data?.left_foot?.posture}
            meanAngle={data?.left_foot?.mean_angle}
            angles_list={data?.left_foot?.angles_during_footlanded}
            side={'left'}
          />
          <PronationMetricsPanel
            posture={data?.right_foot?.posture}
            meanAngle={data?.right_foot?.mean_angle}
            angles_list={data?.right_foot?.angles_during_footlanded}
            side={'right'}
          />
        </div>
      </div>
      {locked ? (
        <div
          style={styles.lockedContainer}
          onClick={() => modalRef?.current?.open()}>
          <Lock height={20} width={20} />
          <TextComponent
            text={translate('Unlock')}
            variant={H6}
            sx={styles.textUnlock}
          />
        </div>
      ) : null}
    </div>
  );
}
const styles = {
  blur: {
    filter: 'blur(30px)',
    pointerEvents: 'none',
    userSelect: 'none',
  },
  container: {display: 'flex', flexDirection: 'row'},
  metricsContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    bacgroundColor: 'red',
    flexDirection: 'column',
  },
  metricsSubContainer: {display: 'flex', flexDirection: 'row'},
  text: {
    color: '#FF884D',
    fontWeight: 'light',
  },
  videoContainer: {
    display: 'flex',
    height: '90vh',
  },
  warningIcon: {
    display: 'flex',
  },
  lockedContainer: {
    display: 'flex',
    flexDirection: 'row',
    top: '15%',
    left: '55%',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    cursor: 'pointer',
  },
  textUnlock: {
    color: colors.YELLOW,
    marginLeft: '1vh',
  },
};

import React from "react";
import JointAngles from "../../JointAngles";


function GraphPanel({ graphData, videoOrientation }) {

  return (
    <div style={styles.container}>
      <JointAngles jointAngles={graphData?.joint_angles} direction={graphData?.direction} videoOrientation={videoOrientation} />


    </div>
  );
}

const styles = {
  container: {
    marginTop: 10,
  },

};

export default GraphPanel;

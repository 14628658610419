import TextComponent from 'components/atoms/TextComponent';
import { BODY_1, BOLD, H5 } from 'constant/constants';
import React from 'react';
import { translate } from 'services/remoteConfig';
import { colors } from 'themes';
import { ReactComponent as Height } from 'assets/svg/height.svg';
import { ReactComponent as Weight } from 'assets/svg/weight.svg';
import HeightComponent from 'components/atoms/HeightComponent';
import WeightComponent from 'components/atoms/WeightComponent';
function OnBoardingAthleteCard({ title, height, weight }) {
  return (
    <div
      style={{
        backgroundColor: colors?.WHITE_OPACITY_06,
        width: '100%',
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 15,
        borderRadius: 10,
      }}>
      <TextComponent text={title} weight={BOLD} variant={H5} />
      <div style={{ display: "flex", alignItems: "center" }}>
        <HeightComponent height={height} />
        <WeightComponent weight={weight} />
      </div>
    </div>
  );
}

export default OnBoardingAthleteCard;

export const score_colors = {
  score_bad: {
    fill: '#6E2515',
    stroke: '#FF4117',
    background: '#852611',
    scoreBackground: 'rgba(255, 65, 23, 0.2)',
    analysisBackground: 'rgba(255, 65, 23, 0.05)',
    overstrideBackground: 'rgba(255, 65, 23, 0.1)',
    svgBackground: '#311A15',
  },
  score_okay: {
    fill: '#6A5C0C',
    stroke: '#F5D000',
    background: '#806D05',
    scoreBackground: 'rgba(245, 208, 0, 0.2)',
    overstrideBackground: 'rgba(245, 208, 0, 0.05)',
    svgBackground: '#2F2B12',
  },
  score_good: {
    fill: '#3A551F',
    stroke: '#78BD32',
    background: '#41641E',
    scoreBackground: 'rgba(120, 189, 50, 0.2)',
    analysisBackground: 'rgba(120, 189, 50, 0.05)',
    overstrideBackground: 'rgba(120, 189, 50, 0.05)',
    svgBackground: '#202918',
  },
  score_grey: {
    background: '#313131',
    scoreBackground: '#242423',
    stroke: '#E8E8E8',
  },
  Easy: {
    fill: 'rgba(120, 189, 50, 0.5)',
    stroke: '#78BD32',
    background: '#41641E',
    scoreBackground: 'rgba(120, 189, 50, 0.2)',
  },
  Medium: {
    fill: 'rgba(245, 208, 0, 0.5)',
    stroke: '#F5D000',
    background: '#806D05',
    scoreBackground: 'rgba(245, 208, 0, 0.2)',
  },
  Hard: {
    fill: 'rgba(255, 65, 23, 0.5)',
    stroke: '#FF4117',
    background: '#852611',
    scoreBackground: 'rgba(255, 65, 23, 0.2)',
  },
};

export const segment_ponderation = {
  RightHip: 15,
  LeftKnee: 8,
  RightElbow: 6,
  RightEar: 4,
  RightKnee: 2,
  foot: 0,
};

export const scoreMapping = {
  score_bad: 5,
  score_okay: 3,
  score_good: 0.5,
};

export const keyMapping = {
  head: 'RightEar',
  trunk: 'RightHip',
  arm: 'RightElbow',
  forwardCycle: 'RightKnee',
  backCycle: 'LeftKnee',
};

import TextComponent from 'components/atoms/TextComponent';

import {BODY_1, BODY_2, BODY_4, BOLD} from 'constant/constants';
import {colors} from 'themes';

import {ReactComponent as AnalysisIcon} from 'assets/svg/analysisIcon.svg';
import {ReactComponent as Eye} from 'assets/svg/eye.svg';
import {ReactComponent as Phone} from 'assets/svg/phone.svg';
import {ReactComponent as PhoneMooving} from 'assets/svg/phoneMooving.svg';
import {ReactComponent as Walk} from 'assets/svg/walk.svg';
import {ReactComponent as StopWatch} from 'assets/svg/stopWatchEmpty.svg';

import {ReactComponent as BackView} from 'assets/svg/backView.svg';
import {ReactComponent as SideView} from 'assets/svg/sideView.svg';
import {ReactComponent as BackViewLegs} from 'assets/svg/backViewLegs.svg';

export const videoTutosContent = [
  {
    vertical: false,
    videoUri:
      'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2Fvideos%2FVideoTuto%2FgoodVideo.mp4?alt=media&token=5e43e064-9351-4c76-ae9e-29ffccf53447',
    videoTextGood: [
      {
        icon: (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <TextComponent text={'10s'} variant={BODY_1} weight={BOLD} />
          </div>
        ),
        text: 'TenSecMaxPerVideo',
      },
      {
        icon: <Eye fill={colors.WHITE} style={{height: 25, width: 25}} />,
        text: 'FullBodyVisible',
      },
      {
        icon: (
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
            }}>
            <TextComponent text={1} variant={BODY_2} weight={BOLD} />
            <AnalysisIcon fill={colors.WHITE} style={{height: 20, width: 20}} />
          </div>
        ),
        text: 'OnePersonPerVideo',
      },
      {
        icon: <TextComponent text={'6'} variant={BODY_2} weight={BOLD} />,
        text: 'FourStrideMinimum',
      },
      {
        icon: (
          <Phone
            fill={colors.WHITE}
            height={25}
            width={25}
            style={{height: 25, width: 25, transform: [{rotate: '-90deg'}]}}
          />
        ),
        text: 'TakeVideoHorizontal',
      },
    ],
    videoTextBad: [
      {
        icon: (
          <PhoneMooving
            fill={colors.WHITE}
            height={25}
            width={25}
            style={{height: 30, width: 30}}
          />
        ),
        text: 'DontFollowRunner',
      },
      {
        icon: (
          <BackView
            fill={colors.WHITE}
            height={25}
            width={25}
            style={{height: 25, width: 25}}
          />
        ),
        text: 'DontFilmBackViewOrFrontView',
      },
    ],
  },
  {
    vertical: true,
    videoUri:
      'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FvideoTuto%2FGoodVideos%2FTreadmillPreview.mp4?alt=media&token=5e21f0df-3bf9-4c74-aeaa-4bebb91bf019',
    videoTextGood: [
      {
        icon: (
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
            }}>
            <TextComponent text={'10s'} variant={BODY_2} weight={BOLD} />
          </div>
        ),
        text: 'TenSecMaxPerVideo',
      },
      {
        icon: <Eye fill={colors.WHITE} style={{height: 25, width: 25}} />,
        text: 'FullBodyVisible',
      },
      {
        icon: <TextComponent text={'6'} variant={BODY_2} weight={BOLD} />,
        text: 'FourStrideMinimum',
      },
      {
        icon: (
          <SideView
            fill={colors.WHITE}
            height={35}
            width={35}
            style={{marginLeft: 2}}
          />
        ),
        text: 'TakeVideoSideView',
      },
    ],
    videoTextBad: [
      {
        icon: (
          <PhoneMooving
            fill={colors.WHITE}
            height={25}
            width={25}
            style={{height: 30, width: 30}}
          />
        ),
        text: 'DontMovePhone',
      },
      {
        icon: (
          <div style={{flexDirection: 'row', alignItems: 'center'}}>
            <TextComponent text={'+'} variant={BODY_2} weight={BOLD} />
            <AnalysisIcon fill={colors.WHITE} style={{height: 20, width: 20}} />
          </div>
        ),
        text: 'MultiplePerson',
      },
    ],
  },
  {
    vertical: true,
    videoUri:
      'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FvideoTuto%2FGoodVideos%2FpronationSupinationPreview.mp4?alt=media&token=fcea67e4-df0a-4859-83e0-51da7fafbc92',
    videoTextGood: [
      {
        icon: (
          <Walk
            fill={colors.WHITE}
            height={30}
            width={30}
            style={{marginLeft: 2}}
          />
        ),
        text: 'WalkOnTreadmill',
      },
      {
        icon: (
          <BackViewLegs
            fill={colors.WHITE}
            style={{height: 30, width: 30}}
            height={30}
            width={30}
          />
        ),
        text: 'LegsFullVisible',
      },
      {
        icon: (
          <div>
            <TextComponent
              text={"10'"}
              variant={BODY_4}
              weight={BOLD}
              sx={{}}
            />
          </div>
        ),
        text: 'TenSecMaxPerVideo',
      },
      {
        icon: <TextComponent text={'6'} variant={BODY_2} weight={BOLD} />,
        text: 'FourStrideMinimum',
      },
      {
        icon: (
          <Phone
            fill={colors.WHITE}
            height={25}
            width={25}
            style={{height: 25, width: 25}}
          />
        ),
        text: 'TakeVideoVertical',
      },
    ],
    videoTextBad: [
      {
        icon: (
          <SideView
            fill={colors.WHITE}
            height={30}
            width={30}
            style={{marginLeft: 2}}
          />
        ),

        text: 'DontFilmSideView',
      },
      {
        icon: (
          <AnalysisIcon fill={colors.WHITE} style={{height: 20, width: 20}} />
        ),
        text: 'DontRunOnTreadmill',
      },
      {
        icon: (
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
            }}>
            <TextComponent text={'+'} variant={BODY_2} weight={BOLD} />
            <AnalysisIcon fill={colors.WHITE} style={{height: 20, width: 20}} />
          </div>
        ),
        text: 'MultiplePerson',
      },
    ],
  },
];

export const videoTutoTitle = [
  'SideViewRunning',
  'SideViewTreadmill',
  'BackViewTreadmill',
];

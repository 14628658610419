import React from 'react';

import TextComponent from 'components/atoms/TextComponent';
import Ruler from './Ruler';
import FootPhases from './FootPhases';

import {translate} from 'services/remoteConfig';

import {
  BLUE,
  BODY_2,
  BOLD,
  FORE_FOOT,
  H5,
  H6,
  MEDIUM,
  MID_FOOT,
  REAR_FOOT,
} from 'constant/constants';

import {images} from 'themes';
import CardWithRoundedIcon from 'components/molecules/CardWithRoundedIcon';
import {ReactComponent as FootIcon} from 'assets/svg/foot.svg';

function BodyContainerContentFootUpdated({
  locked,
  displayData,
  overstrideData,
}) {
  function rotateSvg() {
    const footStrikePattern = displayData?.footStrikePattern || '';

    switch (footStrikePattern) {
      case FORE_FOOT:
        return '50deg';
      case MID_FOOT:
        return '15deg';
      case REAR_FOOT:
        return '-10deg';
      default:
        return '15deg';
    }
  }

  return (
    <div
      style={{
        ...styles.sidePan,
        ...(locked ? styles.lockedContainer : null),
      }}>
      <div style={styles.container}>
        <TextComponent
          text={displayData?.overstrideTitle}
          variant={H5}
          sx={styles.title}
        />
        <img
          src={images[displayData?.asset_id]}
          alt="ankle"
          style={styles.asset}
        />
        <div style={styles.angles}>
          <TextComponent
            text={translate('OverstrideAngle')}
            weight={MEDIUM}
            variant={H6}
            sx={styles.subtitle}
          />
          <Ruler
            rangeGood={[-10, 5]}
            rangeOkay={[5, 10]}
            angle={overstrideData?.mean_angle}
          />
          <TextComponent
            text={displayData?.overstrideExplanation}
            variant={BODY_2}
          />
        </div>

        <CardWithRoundedIcon
          type={BLUE}
          icon={FootIcon}
          svgRotation={rotateSvg()}>
          <TextComponent text={displayData?.text1} variant={H6} weight={BOLD} />
          <TextComponent
            text={displayData?.footStrikeExplanation}
            variant={BODY_2}
          />
        </CardWithRoundedIcon>
        <FootPhases displayData={displayData} />
      </div>
    </div>
  );
}

const styles = {
  angles: {
    marginBottom: '4vh',
  },
  asset: {
    alignSelf: 'center',
    width: '70%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 15,
    width: '95%',
  },
  footAsset: {
    alignSelf: 'center',
    height: '100px',
    objectFit: 'cover',
    width: '30%',
  },
  footContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  lockedContainer: {
    filter: 'blur(30px)',
    pointerEvents: 'none',
    userSelect: 'none',
  },
  sidePan: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  subtitle: {
    marginBottom: '2vh',
    marginTop: '3vh',
  },
  title: {
    marginBottom: '3vh',
    textAlign: 'center',
  },
};

export default BodyContainerContentFootUpdated;

import React from 'react';
import OnBoardingAnalysisContainer from '../../athleteOnBoarding/analysis/components/OnBoardingAnalysisContainer';
import {useNavigate} from 'react-router-dom';
import {EXEMPLE_VIDEO_URL, GOOD_EXEMPLE_URL} from 'constant/contentConstant';
import TextComponent from 'components/atoms/TextComponent';
import {onBoardingTranslate, translate} from 'services/remoteConfig';
import {COACH, H4, H5, REGISTRATION_DATA} from 'constant/constants';
import {createTextObjectReusable} from 'utils/utils';
import {colors} from 'themes';
import VideoComponent from 'screen/analysis/AnalysisViewDetailsScreen/components/VideoComponent';
import OnBoardingBottomArrow from '../../components/OnBoardingBottomArrow';

function OnBoardingCoachCompare() {
  const navigate = useNavigate();

  const userType = JSON.parse(
    localStorage.getItem(REGISTRATION_DATA),
  )?.userType;

  function handleArrowPressed() {
    navigate('/registration/coach/comments');
  }

  function handleBackArrowPressed() {
    if (userType === COACH) {
      navigate('/registration/coach/progress');
    } else {
      navigate('/registration/coach/analysis', {
        state: {value: 1},
      });
    }
  }
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}>
      <div style={{width: '50%'}}>
        <VideoComponent
          video={EXEMPLE_VIDEO_URL}
          speed={0.15}
          loop={true}
          videoStyle={{height: '45vh'}}
          controls={true}
        />
        <VideoComponent
          video={GOOD_EXEMPLE_URL}
          speed={0.3}
          loop={true}
          videoStyle={{height: '45vh'}}
          controls={true}
        />
      </div>
      <div
        style={{
          textAlign: 'center',
          width: '30%',
        }}>
        <TextComponent
          text={translate('Compare')}
          variant={H4}
          sx={{textAlign: 'center', marginTop: 2, marginBottom: 3}}
        />
        <TextComponent
          multipleText={createTextObjectReusable(
            onBoardingTranslate('OnBoardingCompareCoach'),
            {
              variant: H5,
              style: {textAlign: 'center'},
            },
            {
              variant: H5,
              style: {color: colors?.SECONDARY, textAlign: 'center'},
            },
          )}
        />
      </div>
      <OnBoardingBottomArrow onPress={handleBackArrowPressed} previous={true} />
      <OnBoardingBottomArrow onPress={handleArrowPressed} />
    </div>
  );
}

export default OnBoardingCoachCompare;

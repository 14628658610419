import React, {useEffect, useState} from 'react';

import {useLocation, useNavigate} from 'react-router-dom';

import OnBoardingAnalysisContainer from '../../athleteOnBoarding/analysis/components/OnBoardingAnalysisContainer';
import TextComponent from 'components/atoms/TextComponent';
import OnBoardingCoachMetrics from './OnBoardingCoachMetrics';
import OnBoardingCoachComment from './OnBoardingCoachComment';
import OnBoardingCoachAnalysisSegments from './OnBoardingCoachAnalysisSegments';

import {
  onBoardingTranslate,
  translate,
  USER_LOCALE,
} from 'services/remoteConfig';
import {mpTrack} from 'services/mixpanel';

import {createTextObjectReusable} from 'utils/utils';

import {
  ATHLETE,
  BODY_1,
  COACH,
  H4,
  H5,
  MEDIUM,
  PROFESSIONAL,
  REGISTRATION_DATA,
} from 'constant/constants';

import {colors} from 'themes';
import OnBoardingCoachGraphs from './OnBoardingCoachGraphs';
import {EXEMPLE_VIDEO_URL, GOOD_EXEMPLE_URL} from 'constant/contentConstant';
import OnBoardingCoachSegmentsList from './OnBoardingCoachSegmentsList';
import AnalysisRightPanelMetrics from 'screen/analysis/AnalysisViewDetailsScreen/components/analysisRightPanel/AnalysisRightPanelMetrics';

const OnBoardingAnalysisIntro = () => {
  return (
    <div style={styles.container} key="step-1">
      <TextComponent
        text={translate('InstantMarkup')}
        variant={H4}
        weight={MEDIUM}
        sx={{textAlign: 'center', marginBottom: 5}}
      />
      <TextComponent
        multipleText={createTextObjectReusable(
          onBoardingTranslate('AnglesOnVideo'),
          {
            variant: H5,
          },
          {
            variant: H5,
            style: {textAlign: 'center', color: colors.SECONDARY, marginTop: 5},
          },
        )}
        center={true}
      />
      <TextComponent
        text={onBoardingTranslate('CoachAnalysisTransition')}
        variant={BODY_1}
        weight={MEDIUM}
        sx={{textAlign: 'center', marginTop: 5, marginBottom: 3}}
      />
    </div>
  );
};

function OnBoardingCoachAnalysisWrapper() {
  const [selectedSegment, setSelectedSegment] = useState(null);
  const location = useLocation();
  const [step, setStep] = useState(location?.state?.value || 0);
  const navigate = useNavigate();

  const userType = JSON.parse(
    localStorage.getItem(REGISTRATION_DATA),
  )?.userType;

  useEffect(() => {
    if (userType === COACH) {
      switch (step) {
        case 0:
          mpTrack('Web App OnBoarding Coach Markup Presentation Screen Viewed');
          break;
        case 1:
          mpTrack('Web App OnBoarding Coach Metrics List Screen Viewed');
          break;
        case 2:
          mpTrack('Web App OnBoarding Coach Segment List Screen Viewed');
          break;
        case 3:
          mpTrack('Web App OnBoarding Coach Write Comment Screen Viewed');
          break;
        default:
          break;
      }
    } else {
      switch (step) {
        case 0:
          mpTrack(
            'Web App OnBoarding Profesional Markup Presentation Screen Viewed',
          );
          break;
        case 1:
          mpTrack('Web App OnBoarding Profesional Metrics List Screen Viewed');
          break;
        case 2:
          mpTrack('Web App OnBoarding Profesional Write Comment Screen Viewed');
          break;
        default:
          break;
      }
    }
  }, [userType, step]);

  const steps = {
    coach: [
      {child: <OnBoardingAnalysisIntro key="step-1" />},
      {
        child: (
          <OnBoardingCoachAnalysisSegments
            selectedSegment={selectedSegment}
            setSelectedSegment={setSelectedSegment}
            key="step-2"
          />
        ),
        complementaryChild: (
          <>
            <div style={{textAlign: 'center', marginTop: 10}}>
              <TextComponent
                multipleText={createTextObjectReusable(
                  onBoardingTranslate('AnalysisSegmentSubtitle'),
                  {style: {textAlign: 'center'}, variant: H5},
                  {
                    style: {color: colors?.SECONDARY, textAlign: 'center'},
                    variant: H5,
                  },
                )}
              />
            </div>
            <OnBoardingCoachSegmentsList
              selectedSegment={selectedSegment}
              setSelectedSegment={setSelectedSegment}
            />
          </>
        ),
      },
      {
        complementaryChild: (
          <OnBoardingCoachMetrics
            key="step-3"
            displayData={selectedSegment}
            setDisplayData={setSelectedSegment}
          />
        ),
        child: selectedSegment && (
          <AnalysisRightPanelMetrics displayData={selectedSegment} />
        ),
      },
      {child: <OnBoardingCoachGraphs key="step-" />},
    ],
    professional: [
      {child: <OnBoardingAnalysisIntro key="step-1" />},
      {
        complementaryChild: (
          <OnBoardingCoachMetrics
            key="step-3"
            displayData={selectedSegment}
            setDisplayData={setSelectedSegment}
          />
        ),
        child: <AnalysisRightPanelMetrics displayData={selectedSegment} />,
      },
    ],
    athlete: [
      {
        child: (
          <>
            <TextComponent
              text={translate('Analyse')}
              variant={H4}
              sx={{textAlign: 'center', marginTop: 2}}
            />
            <TextComponent
              text={translate('AnalyseOnboardingDesc')}
              variant={H5}
              sx={{marginTop: 3, marginLeft: 1}}
            />
          </>
        ),
      },
      {
        child: selectedSegment && (
          <OnBoardingCoachAnalysisSegments
            selectedSegment={selectedSegment}
            setSelectedSegment={setSelectedSegment}
            key="step-2"
          />
        ),
        complementaryChild: (
          <>
            <div style={{textAlign: 'center', marginTop: 10}}>
              <TextComponent
                text={translate('OnboardingAthleteSeg')}
                variant={H5}
              />
            </div>
            <OnBoardingCoachSegmentsList
              selectedSegment={selectedSegment}
              setSelectedSegment={setSelectedSegment}
            />
          </>
        ),
      },
    ],
  };

  const nextStep = () => {
    if (step < steps[userType].length - 1) {
      setStep(step + 1);
    } else {
      console.log(userType);
      if (userType === COACH) {
        navigate('/registration/coach/progress');
      } else if (userType === PROFESSIONAL) {
        navigate('/registration/coach/compare');
      } else {
        navigate('/registration/athlete/train');
      }
    }
  };

  const previousStep = () => {
    if (step > 0) {
      setStep(step - 1);
    } else {
      console.log(userType);
      if (userType === COACH) {
        navigate('/registration/coach/athlete-presentation');
      } else if (userType === PROFESSIONAL) {
        navigate('/registration/coach/specialization');
      } else {
        navigate('/registration/athlete/analysis-introduction');
      }
    }
  };
  return (
    <OnBoardingAnalysisContainer
      videoUri={userType === ATHLETE ? EXEMPLE_VIDEO_URL : GOOD_EXEMPLE_URL}
      videoSpeed={0.4}
      handleNextArrowClicked={nextStep}
      handlePreviousArrowClicked={previousStep}
      step={step}
      childrenUnderVideo={steps[userType][step]?.complementaryChild}>
      {steps[userType][step]?.child}
    </OnBoardingAnalysisContainer>
  );
}

const styles = {
  container: {
    marginTop: '1vh',
  },
};

export default OnBoardingCoachAnalysisWrapper;

import {
  getArmText,
  getBackText,
  getHeadText,
  getKneeBackText,
  getKneeFrontText,
  getFootText,
} from 'utils/textFromAngles';
import {getAnalysis, translate} from './remoteConfig';
import {ANALYSIS, GRAPH, METRICS, STYLE} from 'constant/constants';

//changer tout on récupère le Json => renvoyer les data des analyses et puis point barre
// pas besoin du text from angles et tout on a le json
//vu qu'on a le json on a besoin de rien d'autre
// si on

export function getAnalysisTexts(json) {
  const json_version = json?.json_version;

  if (json_version > 2) {
    if (
      (json?.foot_strike?.trunk_mean_angle ||
        json?.foot_strike?.trunk_mean_angle === 0) &&
      (json?.foot_strike?.head_mean_angle ||
        json?.foot_strike?.head_mean_angle === 0) &&
      (json?.foot_strike?.arm_mean_angle ||
        json?.foot_strike?.arm_mean_angle === 0) &&
      (json?.foot_strike?.front_leg_mean_angle ||
        json?.foot_strike?.front_leg_mean_angle === 0) &&
      (json?.foot_strike?.back_leg_mean_angle ||
        json?.foot_strike?.back_leg_mean_angle === 0)
    ) {
      const texts = [
        getFootText(json?.analysis_content?.foot),
        getHeadText(
          json?.analysis_content?.head,
          json?.foot_strike?.head_mean_angle,
          json_version,
        ),
        getBackText(
          json?.analysis_content?.trunk,
          json?.foot_strike?.trunk_mean_angle,
          json_version,
        ),
        getArmText(
          json?.analysis_content?.arm,
          json?.foot_strike?.arm_mean_angle,
          json_version,
        ),
        getKneeFrontText(
          json?.analysis_content?.forward_cycle,
          json?.foot_strike?.front_leg_mean_angle,
          json_version,
        ),
        getKneeBackText(
          json?.analysis_content?.back_cycle,
          json?.foot_strike?.back_leg_mean_angle,
          json_version,
        ),
      ];

      return texts;
    }
  } else return {error: 'ObsoleteAnalysis'};
}

export function prepareTexts(
  text,
  angle,
  color,
  image,
  type,
  idealImage,
  scoreNumber,
) {
  return {
    angle: angle,
    title: text?.title,
    subtitile1: 'Position',
    text1: text?.position,
    subtitile2: translate('Explanation'),
    text2: text?.explanation,
    subtitile3: translate('Exercice'),
    exercice: text?.exercice,
    advice: text?.advice,
    backgroundColor: color,
    image: image,
    score: text?.score,
    scoreNumber: scoreNumber,
    type: type,
    idealImage: idealImage,
  };
}

export function getAnalysisDataWithPanel(jsonData, panel) {
  if (!jsonData) return;

  if (panel === ANALYSIS) {
    return getAnalysisTexts(jsonData);
  } else if (panel === STYLE) {
    if (jsonData?.running_styles?.length === 0) return [];
    if (jsonData?.running_styles && jsonData?.running_styles?.length > 1)
      return {
        running_styles:
          jsonData?.running_styles[0]?.percentage >
          jsonData?.running_styles[1]?.percentage
            ? jsonData?.running_styles[0]
            : jsonData?.running_styles[1],
      };
    else if (jsonData?.running_styles?.length === 1)
      return {
        running_styles: jsonData?.running_styles[0],
      };
    else return null;
  } else if (panel === METRICS) {
    return {
      time_between_steps: jsonData?.metrics?.time_of_flight_second,
      ground_contact_time: jsonData?.metrics?.ground_contact_time_second,
      steps_per_min: jsonData?.metrics?.step_frequency_steps_per_min,
      duty_factor: jsonData?.metrics?.duty_factor_percent,
      stride_length: jsonData?.metrics?.step_length_meter * 2,
      step_length: jsonData?.metrics?.step_length_meter,
    };
  } else if (panel === GRAPH) {
    return {
      joint_angles: jsonData?.joint_angles,
      direction: jsonData?.direction,
    };
  }
}

import React from 'react';

import { pdf, } from '@react-pdf/renderer';
import { useSelector } from 'react-redux';

import ButtonComponent from 'components/atoms/ButtonComponent';

import { USER_LOCALE, translate } from 'services/remoteConfig';
import { mpTrack } from 'services/mixpanel';

import { selectBrandLogo } from "slices/userSlice";

import { PDF } from 'screen/analysis/PdfExport/Pdf';

const GeneratePDF = ({ data, jsonData, itemsToDisplay, setSucces, step, setStep, exportJointAnglesToPDF }) => {
    const brandLogo = useSelector(selectBrandLogo);
    const analysisData = useSelector((state) => state?.analysis?.listAnalysisData);
    const analysisDB = analysisData?.find((item) => item?.data?.uploadVideoId === jsonData?.video_id);
    const averageScore = jsonData?.total_score
    const heightUnity = useSelector((state) => state?.unity?.height);

    function onGeneratePDF() {
        if ((step === 1 && itemsToDisplay[2]?.JointAngles === 'joint_angles') || (step === 0 && (itemsToDisplay[2]?.JointAngles !== 'joint_angles'))) {
            generatePDF()
                .then(() => {
                    setSucces(true);
                    mpTrack('WebApp Generate PDF button clicked');
                })
                .catch(error => {
                    console.error('Error generating PDF:', error);
                });
        }
        else {
            setStep(1);
        }

    }

    const generatePDF = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const jointAnglesData = itemsToDisplay[2]?.JointAngles ? await exportJointAnglesToPDF() : null;
                const doc = <PDF
                    data={itemsToDisplay[0]?.Segments?.length > 0 && data?.slice(1)?.filter((item) => itemsToDisplay[0]?.Segments?.includes(item?.type))}
                    itemsToDisplay={itemsToDisplay}
                    jsonData={jsonData}
                    brandLogo={brandLogo}
                    averageScore={averageScore}
                    analysisDB={analysisDB}
                    foot={itemsToDisplay[0]?.Segments?.includes('RightAnkle') && data[0]}
                    heightUnity={heightUnity}
                    jointAnglesData={jointAnglesData}
                />;
                const asPdf = pdf([]);
                asPdf.updateContainer(doc);
                const blob = await asPdf.toBlob();
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${analysisDB?.data?.tag?.name}_${new Date(analysisDB?.date).toLocaleDateString(USER_LOCALE, {
                    month: "numeric",
                    day: "numeric",
                })}.pdf`;
                a.click();
                URL.revokeObjectURL(url);
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    };

    return (
        <div style={{ marginTop: "2vh" }}>
            <ButtonComponent type="orange" onClick={onGeneratePDF} title={translate('GeneratePDF')} />
        </div>
    );
};



export default GeneratePDF;

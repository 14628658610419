import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';

import { useLocation } from 'react-router-dom';
import Lottie from 'lottie-react';
import { useSelector } from 'react-redux';
import { Fade, Modal } from '@mui/material';

import PronationAnalysis from './components/pronationAnalysis/PronationAnalysis';
import RunningAnalysis from './RunningAnalysis';
import TextComponent from 'components/atoms/TextComponent';
import ButtonComponent from 'components/atoms/ButtonComponent';

import { translate } from 'services/remoteConfig';
import { removeCredit } from 'services/usageApi';
import { updateAnalysis } from 'services/analysisApi';
import { mpTrack } from 'services/mixpanel';

import { useUsageChanges } from 'hooks/useUsageChanges';

import { ORANGE } from 'constant/constants';

import { colors } from 'themes';

import fireworks from 'assets/animations/fireworks.json';
import { ReactComponent as Close } from 'assets/svg/close.svg';
import { useAnalysisListener } from 'hooks/useAnalysisChanges';

function Analysis() {
  useUsageChanges();

  const location = useLocation();
  const analysisId = location?.state?.analysis?.data?.uploadVideoId;

  const { analysis } = useAnalysisListener(analysisId);

  const jsonData = JSON.parse(
    localStorage.getItem(analysis?.data?.uploadVideoId),
  );
  const usageSlice = useSelector(state => state.usage);

  const modalRef = useRef();
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [locked, setLocked] = useState(null);
  const [trackingEvent, setTrackingEvent] = useState(null);

  useEffect(() => {
    if (analysis?.data?.locked !== undefined) setLocked(analysis?.data?.locked);
  }, [analysis?.data?.locked]);

  const handleClose = () => {
    setOpen(false);
  };

  useImperativeHandle(modalRef, () => ({
    getOpen() {
      return open;
    },
    open(trackingEvent = null) {
      setTrackingEvent(trackingEvent);
      setOpen(true);
    },
    setClose() {
      setOpen(false);
    },
  }));

  function unlockAnalysis() {
    setLoading(true);
    //remove a credit from user's account and then update analysis with locked = false
    removeCredit();
    const timeoutId = setTimeout(() => {
      mpTrack('Web App' + trackingEvent + 'Unlocked');
      setSuccess(true);
      setLoading(false);
      setLocked(false);
      updateAnalysis(
        { data: { locked: false } },
        analysis?.data?.uploadVideoId,
      ).then(() => {
        setTimeout(() => {
          handleClose();
        }, 2000);
      });
    }, 1500);
    return () => clearTimeout(timeoutId);
  }

  function onLockedPress() {
    if (
      usageSlice?.creditNumber > 0 ||
      (usageSlice?.monthlyPlan === true && usageSlice?.monthlyCreditNumber > 0) ||
      usageSlice?.yearlyPlan === true ||
      loading === true ||
      success === true
    ) {
      return (
        <>
          <TextComponent
            text={translate('UnlockWithCredit')}
            sx={styles.title}
          />
          {success === true && !loading ? (
            <>
              <Lottie animationData={fireworks} style={styles.fireworks} />
              <TextComponent
                text={translate('CongratsUnlock')}
                sx={styles.congratsUnlock}
              />
            </>
          ) : (
            <ButtonComponent
              onClick={unlockAnalysis}
              loading={loading}
              type={ORANGE}
              title={translate('Unlock')}
            />
          )}
        </>
      );
    } else {
      return (
        <>
          <TextComponent
            text={
              usageSlice?.monthlyPlan === true &&
                usageSlice?.monthlyCreditNumber === 0
                ? translate('OutOfCredits')
                : translate('FreemiumFeatureOnly')
            }
            sx={styles.title}
          />
          <TextComponent
            text={translate('ContactChat')}
            sx={styles.contactChat}
          />
        </>
      );
    }
  }
  function renderAnalysis() {
    if (analysis?.analysis_type === 'pronation' && analysis) {
      return (
        <PronationAnalysis
          data={jsonData}
          locked={locked}
          modalRef={modalRef}
        />
      );
    } else if (Object.entries(analysis).length > 0) {
      return (
        <RunningAnalysis
          jsonData={jsonData}
          locked={locked}
          modalRef={modalRef}
          orientation={analysis?.video?.orientation}
        />
      );
    }
  }

  return (
    <div>
      {renderAnalysis()}

      <Modal
        open={open}
        onClose={handleClose}
        slotProps={{
          backdrop: {
            style: { backgroundColor: 'transparent' },
          },
        }}
        sx={styles.modal}>
        <Fade in={open}>
          <div style={styles.container}>
            <div
              style={styles.closeCross}
              onClick={event => {
                event?.preventDefault();
                event?.stopPropagation();
                handleClose();
              }}>
              <Close stroke="white" width={50} height={50} />
            </div>
            {onLockedPress()}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

const styles = {
  button: {
    marginBottom: '2vh',
  },

  closeCross: {
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 2,
  },
  congratsUnlock: {
    color: colors.VALID_IMAGE,
    fontSize: '1.2vw',
    marginTop: '2vh',
  },
  contactChat: {
    color: colors.SECONDARY,
    fontSize: '0.8vw',
    textAlign: 'center',
  },
  container: {
    backgroundColor: colors.BLACK,
    width: '30vw',
    position: 'relative',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '20vh',
  },
  fireworks: {
    position: 'absolute',
    bottom: '0',
    left: '0',
    width: '100%',
    height: '100%',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '60vh',
  },
  title: {
    color: colors.WHITE,
    margin: '2vh',
    fontSize: '1.2vw',
    width: '90%',
  },
};

export default Analysis;

import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import { metricsConfig } from "screen/analysis/AnalysisViewDetailsScreen/config/metricsConfig";
import { translate } from "services/remoteConfig";
import { mToftIn } from "utils/converter";
import { DISTANCE_METRIC_FT } from "constant/constants";
import { colors, images } from "themes";

export default function PdfMetrics({ metrics, jsonData, heightUnity }) {

    const duty_factor = metricsConfig(
        'duty_factor',
        jsonData?.metrics?.duty_factor_percent,
    );

    return (
        <>
            {metrics?.includes('time_of_flight') && (
                <View style={styles.section} wrap={false}>
                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>
                        <View style={{ width: '65%' }}>
                            <Text style={{ color: colors.SECONDARY, fontSize: 20 }}>
                                {jsonData?.metrics?.time_of_flight_second} {translate('Sec')}
                            </Text>
                            <Text style={styles.text}>
                                {translate('TimeBetweenStepsDescription')?.replace(
                                    /\n\n/g,
                                    ' ',
                                )}
                            </Text>
                        </View>
                        <Image
                            source={images.METRICS_TIME_STEPS}
                            style={{
                                height: 75,
                                width: '30%',
                                borderRadius: 15,
                                backgroundColor: colors.APP_BACKGROUND,
                                objectFit: 'contain',
                            }}
                        />
                    </View>
                </View>
            )}

            {metrics?.includes('ground_contact_time') && (
                <View style={styles.section} wrap={false}>
                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>
                        <Image
                            source={images.METRICS_GROUND_CONTACT_TIME}
                            style={{
                                height: 75,
                                width: '30%',
                                borderRadius: 15,
                                backgroundColor: colors.APP_BACKGROUND,
                                objectFit: 'contain',
                            }}
                        />

                        <View style={{ width: '65%' }}>
                            <Text style={{ color: colors.SECONDARY, fontSize: 20 }}>
                                {jsonData?.metrics?.ground_contact_time_second}{' '}
                                {translate('Sec')}
                            </Text>
                            <Text style={styles.text}>
                                {translate('GroundContactTimeDescription')}
                            </Text>
                        </View>
                    </View>
                </View>
            )}

            {metrics?.includes('steps_per_min') && (
                <View style={styles.section} wrap={false}>
                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>
                        <View style={{ width: '65%' }}>
                            <Text style={{ color: colors.SECONDARY, fontSize: 20 }}>
                                {jsonData?.metrics?.step_frequency_steps_per_min} {translate('PerMin')}
                            </Text>
                            <Text style={styles.text}>
                                {translate('StepFrequencyDescription')?.replace(
                                    /\n\n/g,
                                    ' ',
                                )}
                            </Text>
                        </View>
                        <Image
                            source={images.METRICS_NUMBER_STEPS}
                            style={{
                                height: 75,
                                width: '30%',
                                borderRadius: 15,
                                backgroundColor: colors.APP_BACKGROUND,
                                objectFit: 'contain',
                            }}
                        />
                    </View>
                </View>
            )}

            {metrics?.includes('duty_factor') && (
                <View style={styles.section} wrap={false}>
                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>
                        <View style={{ width: '30%' }}>
                            <Text style={{ color: colors.SECONDARY, fontSize: 20 }}>
                                Duty factor
                            </Text>
                            <Text style={{ color: colors.SECONDARY, fontSize: 20 }}>
                                {duty_factor?.value} {duty_factor?.unity}
                            </Text>
                        </View>
                        <Text style={{ width: '65%', fontSize: 12 }}>
                            {translate('DutyFactorDesc')}
                        </Text>
                    </View>
                </View>
            )}

            {metrics?.includes('stride_length') && (
                <View style={styles.section} wrap={false}>
                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>
                        <View style={{ width: '65%' }}>
                            {heightUnity === DISTANCE_METRIC_FT ? (
                                <Text style={{ color: colors.SECONDARY, fontSize: 20 }}>
                                    {mToftIn(jsonData?.metrics?.step_length_meter * 2)?.ft} ft{' '}
                                    {mToftIn(jsonData?.metrics?.step_length_meter * 2)?.inch} in
                                </Text>
                            ) : (
                                <Text style={{ color: colors.SECONDARY, fontSize: 20 }}>
                                    {jsonData?.metrics?.step_length_meter * 2}{' '}
                                    {translate('Meters')}
                                </Text>
                            )}

                            <Text style={styles.text}>
                                {translate('StrideLengthDesc')?.replace(/\n\n/g, ' ')}
                            </Text>
                        </View>
                        <Image
                            source={images.METRICS_STRIDE_LENGTH}
                            style={{
                                height: 75,
                                width: '30%',
                                borderRadius: 15,
                                backgroundColor: colors.APP_BACKGROUND,
                                objectFit: 'contain',
                                padding: 5,
                            }}
                        />
                    </View>
                </View>
            )}

            {metrics?.includes('step_length') && (
                <View style={styles.section} wrap={false}>
                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>
                        <Image
                            source={images.METRICS_STEP_LENGTH}
                            style={{
                                height: 75,
                                width: '30%',
                                borderRadius: 15,
                                backgroundColor: colors.APP_BACKGROUND,
                                objectFit: 'contain',
                                padding: 5,
                            }}
                        />

                        <View style={{ width: '65%' }}>
                            {heightUnity === DISTANCE_METRIC_FT ? (
                                <Text style={{ color: colors.SECONDARY, fontSize: 20 }}>
                                    {mToftIn(jsonData?.metrics?.step_length_meter)?.ft} ft{' '}
                                    {mToftIn(jsonData?.metrics?.step_length_meter)?.inch} in
                                </Text>
                            ) : (
                                <Text style={{ color: colors.SECONDARY, fontSize: 20 }}>
                                    {jsonData?.metrics?.step_length_meter} {translate('Meters')}
                                </Text>
                            )}
                            <Text style={styles.text}>
                                {translate('StepLengthDesc')}
                            </Text>
                        </View>
                    </View>
                </View>
            )}
        </>
    )
}

const styles = StyleSheet.create({
    text: {
        fontSize: 12,
        marginBottom: 10,
    },
    section: {
        padding: 10,
        marginBottom: 10,
    },
});

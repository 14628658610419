import React from "react";

import GradientScoreSystem from "./GradientScoreSystem";
import RoundedScoreMeter from "./RoundedScoreMeter";
import TextComponent from "components/atoms/TextComponent";
import { BODY_1, H5 } from "constant/constants";
import { colors } from "themes";
import { translate } from "services/remoteConfig";

function RunningAnalysisBottomCard({ analysisData, locked }) {
  const entriesArray =
    !analysisData?.analysis_content ||
      Object.keys(analysisData?.analysis_content).length < 1
      ? []
      : Object?.entries(analysisData?.analysis_content);


  if (locked) {
    return (
      <div style={styles.locked}
      >
        <TextComponent
          variant={H5}
          sx={{ color: colors.YELLOW }}
          text={translate('FreeAnalysis')}
        />
      </div>
    );
  }

  if (analysisData?.json_version < 3 || !analysisData?.json_version) {
    return (<div style={{ alignItems: 'center', justifyContent: 'center' }}>
      <TextComponent
        variant={BODY_1}
        sx={{ color: colors.RED, justifyContent: 'center' }}
        text={translate('OutdatedAnalysis')}
      />
    </div>)
  }

  return (
    <>
      <GradientScoreSystem scoreNumber={analysisData?.total_score} />
      <div style={styles.container}>
        {entriesArray?.map(([key, value]) => {
          if (key === "foot" && !value?.texts?.overstride_explanation) return null;
          return (
            <RoundedScoreMeter
              key={key}
              scoreNumber={value?.score_number}
              scoreText={value?.texts?.score}
              item={key}
              size={'3.2vw'}
            />
          );
        })}
      </div>
    </>
  );
}

const styles = {
  container: {
    display: "flex",
    right: 0,
    float: "right",
    margin: "0px 5px 0px auto",
  },
  locked: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: '100%'
  }
};

export default RunningAnalysisBottomCard;

import {checkUserPermission} from 'utils/utils';
import {ATHLETE} from './constants';

export const ANALYSIS_ITEMS = [
  {
    category: 'Segments',
    items: {
      RightEar: 'Head',
      RightHip: 'Trunk',
      RightElbow: 'Arm',
      RightKnee: 'ForwardCycle',
      LeftKnee: 'BackCycle',
      RightAnkle: 'FootLanding',
    },
  },
  {
    category: 'Metrics',
    items: {
      time_of_flight_second: 'TimeBetweenBteps',
      ground_contact_time_second: 'GroundContactTime',
      step_frequency_steps_per_min: 'NumberOfStep',
      duty_factor_percent: 'DutyFactor',
      stride_length: 'StrideLength',
      step_length_meter: 'StepLength',
    },
  },
  {
    category: 'Other',
    items: {
      ...(checkUserPermission([ATHLETE]) ? {} : {joint_angles: 'JointAngles'}),
      running_styles: 'RunningStyle',
    },
  },
];

import TextComponent from 'components/atoms/TextComponent';
import { H4 } from 'constant/constants';
import React, { useEffect, useState } from 'react';
import { translate } from 'services/remoteConfig';

import AnalysisCard from '../AnalysisListCard/AnalysisCard';
import NoVideos from './NoVideos';
import { useSelector } from 'react-redux';
import { selectAthleteSelected } from 'slices/athleteSlice';

function AllAnalysis({ analysisList, comparedAnalysis, setComparedAnalysis }) {
  const [analysisListAthlete, setAnalysisListAthlete] = useState([]);
  const athleteSlice = useSelector(selectAthleteSelected);

  useEffect(() => {
    setAnalysisListAthlete([]);
    if (Object.keys(athleteSlice).length > 0) {
      analysisList?.map(analysis => {
        if (analysis?.data?.tag?.id === athleteSlice?.id) {
          setAnalysisListAthlete(prev => [...prev, analysis]);
        }
      });
    } else setAnalysisListAthlete(analysisList);
  }, [athleteSlice, analysisList]);

  if (analysisListAthlete?.length === 0 && athleteSlice) return <NoVideos />;
  else {
    return (
      <>
        <div style={styles.container}>
          {analysisListAthlete?.map((analysis, index) => {
            return (
              <AnalysisCard
                analysisId={analysis?.data?.uploadVideoId}
                key={index}
                slider={false}
                format={'big'}
                comparedAnalysis={comparedAnalysis}
                setComparedAnalysis={setComparedAnalysis}
              />
            );
          })}
        </div>
      </>
    );
  }
}

const styles = {
  container: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'repeat(3, minmax(43vh, 1fr))',
    gridGap: 20,
    width: '98%',
  },
};

export default AllAnalysis;

import React from "react";

import TextComponent from "components/atoms/TextComponent";

import stripedScoreBackground from "assets/svg/stripedScoreBackground.svg";
import { ReactComponent as BottomBarCup } from "assets/svg/bottomBarScore.svg";

import { H6 } from "constant/constants";
import { score_colors } from "../constants/analysisListConstants";

function GradientScoreSystem({ scoreNumber }) {

  return (
    <div
      style={{
        height: "4vh",
        width: "6vw",
        borderRadius: 30,
        backgroundImage: `url(${stripedScoreBackground})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundColor:
          scoreNumber <= 33
            ? score_colors["score_bad"].scoreBackground
            : scoreNumber <= 66
              ? score_colors["score_okay"].scoreBackground
              : score_colors["score_good"].scoreBackground,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1,
        }}
      >
        <BottomBarCup
          fill={
            scoreNumber <= 33
              ? score_colors["score_bad"].stroke
              : scoreNumber <= 66
                ? score_colors["score_okay"].stroke
                : score_colors["score_good"].stroke
          }
          height={"2vh"}
          style={{ flexShrink: 0 }}
        />
        <TextComponent text={scoreNumber + "%"} sx={{ fontSize: '1.2vw' }} />
      </div>
      <div
        style={{
          height: "100%",
          width: scoreNumber + "%",
          backgroundColor:
            scoreNumber <= 33
              ? score_colors["score_bad"].fill
              : scoreNumber <= 66
                ? score_colors["score_okay"].fill
                : score_colors["score_good"].fill,
          position: "absolute",
          left: 0,
        }}
      ></div>
    </div>
  );
}

export default GradientScoreSystem;

import TextComponent from 'components/atoms/TextComponent';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { onBoardingTranslate, translate } from 'services/remoteConfig';
import { colors } from 'themes';
import OnBoardingBottomArrow from '../components/OnBoardingBottomArrow';
import OnBoardingProgressGraph from '../components/OnBoardingProgressGraph';
import { COACH, H4, H5, REGISTRATION_DATA } from 'constant/constants';
import { createTextObjectReusable } from 'utils/utils';
import OnBoardingAthleteCard from '../components/OnBoardingAthleteCard';
import { progressSegments } from '../constants/onBoardingText';
import RoundedScoreMeter from 'screen/analysis/AnalysisListCard/components/RoundedScoreMeter';
import ScoreEvolution from 'screen/progress/components/ScoreEvolution';

function OnBoardingCoachProgress() {
  const navigate = useNavigate();

  const onBoardingUserData = JSON.parse(
    localStorage.getItem(REGISTRATION_DATA),
  );

  function handleArrowPressed() {
    navigate('/registration/coach/compare');
  }

  function handleBackArrowPressed() {
    navigate('/registration/coach/analysis', {
      state: onBoardingUserData?.userType === COACH ? { value: 3 } : { value: 2 },
    });
  }

  function handleProgression(item) {
    switch (item) {
      case 'totalScore':
        return 3;
      case '0':
        return -5;
      case '1':
        return 1;
      case '2':
        return 10;
      case '3':
        return 2;
      case '4':
        return -8;
      default:
        break;
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <div
        style={{
          width: '75%',
          marginTop: '1vh',
          textAlign: 'center',
          marginBottom: 20,
        }}>
        <TextComponent
          multipleText={createTextObjectReusable(
            onBoardingTranslate('ProgressSegmentSubtitle'),
            {
              style: {
                color: colors.WHITE,
                textAlign: 'center',
              },
              variant: H5,
            },
            {
              variant: H5,
              style: { color: colors.SECONDARY, textAlign: 'center' },
            },
            {},
          )}
        />
      </div>
      <OnBoardingProgressGraph />

      <div
        style={{
          marginTop: 10,
          width: '50%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <div style={{ width: '60%', marginTop: '1vh' }}>
          <OnBoardingAthleteCard title={'Perrine'} height={1.70} weight={60} />
        </div>

        <div style={{ marginTop: 30, marginBottom: 30 }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'space-between',
            }}>
            {Object?.entries(progressSegments)?.map(([key, value]) => {
              if (key === 'RightAnkle') return;
              return (
                <div key={key} style={styles.segmentContainer}>
                  <RoundedScoreMeter
                    key={key}
                    score_text={value?.texts?.score}
                    scoreNumber={value?.score_number}
                    item={value?.item}
                    size={80}
                  />
                  <ScoreEvolution
                    progress={handleProgression(key)}
                    segment={'totalScore'}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <OnBoardingBottomArrow onPress={handleArrowPressed} />
      <OnBoardingBottomArrow onPress={handleBackArrowPressed} previous={true} />
    </div>
  );
}

const styles = {
  chart: {
    display: 'flex',
    height: '80vh',
    width: '80vh',
  },
};
export default OnBoardingCoachProgress;

export const EXEMPLE_VIDEO_POSTER =
  'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FOnboarding%2Fimages%2Fposter_onTrack.jpg?alt=media&token=bd0218ec-3ead-41ad-822f-13000a188795';

export const EXEMPLE_VIDEO_URL =
  'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FOnboarding%2Fvideo%2Fperrine_with_drawing_onTrack.mp4?alt=media&token=7e7ce46f-6ea2-4aa3-8670-a09212ee56d7';

export const GOOD_EXEMPLE_VIDEO_POSTER =
  'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FOnboarding%2Fimages%2Fposter_exemple.jpg?alt=media&token=90b0893b-90eb-4ea7-9edd-e4e9cbe603f5';
export const GOOD_EXEMPLE_URL =
  'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FOnboarding%2Fvideo%2Fperrine_with_drawing.mp4?alt=media&token=e26cef2b-5935-412e-91ac-180a04d76a02';

export const EXERCISE_VIDEO_URL =
  'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2Fexercises%2Fvideos%2FFallingLateralLungewithBodyweightPortrait.mp4?alt=media&token=f0d59759-6ae5-4276-b521-61d9cf6e6efc';

export const TREADMILL_EXEMPLE_VIDEO_URL =
  'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FOnboarding%2Fvideo%2Fexemple_treadmill.mp4?alt=media&token=1e149b92-0bcb-4930-9ead-b691503065a5';

export const PRONATION_EXEMPLE_VIDEO_URL =
  'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FvideoTuto%2Fupload%2FanalysisTypes%2FprenationSupinationPreview.mp4?alt=media&token=63f5495d-c58c-45c0-96b3-5be44dd71dd9';

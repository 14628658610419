import React from 'react';
import { colors } from 'themes';
import UsageInfosDisplay from './UsageInfosDisplay';
import ButtonComponent from 'components/atoms/ButtonComponent';
import { ORANGE } from 'constant/constants';
import { translate } from 'services/remoteConfig';

function CoachSubscription({ item, onClick }) {

    function isLegacy() {
        //Check if users are on legacy plans : unlimited analysis on monthly or yearly plans
        if (
            item?.yearlyPlan === true &&
            item?.yearly10 === false &&
            item?.yearly30 === false
        )
            return true;
        if (
            item?.monthlyPlan === true &&
            item?.monthly10 === false &&
            item?.monthly30 === false
        )
            return true;
        return false;
    }
    const legacy = isLegacy();

    return (
        <div style={styles.creditContainer}>
            <div style={styles.CTAContainer}>
                <UsageInfosDisplay legacy={legacy} usageSlice={item} />
            </div>
            <div style={styles.buttonCreditContainer}>
                <ButtonComponent
                    type={ORANGE}
                    title={translate('NeedMoreContactSales')
                    }
                    onClick={() => { onClick('ContactSales') }}
                    sx={styles.button}
                />
            </div>
        </div>
    );
}

const styles = {
    button: {
        fontSize: '0.8vw',
        padding: '1vh'
    },
    buttonCreditContainer: {
        alignItems: 'flex-end',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 20
    },
    CTAContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginTop: 20,
        padding: 8,
    },
    creditContainer: {
        backgroundColor: colors?.WHITE_OPACITY_04,
        borderRadius: 20,
        marginTop: '5%',
        width: '90%',
    },
}

export default CoachSubscription;

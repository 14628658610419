import React, {useEffect, useState} from 'react';

import FadinView from 'components/atoms/FadinView';
import TextComponent from 'components/atoms/TextComponent';
import OnBoardingButtonList from './components/OnBoardingButtonList';

import {onBoardingTranslate} from 'services/remoteConfig';

import {createTextObjectReusable} from 'utils/utils';

import {BOLD, H4, REGISTRATION_DATA} from 'constant/constants';
import {onBoardingButtonUserType} from './constants/onBoardingButtonData.js';

import {colors} from 'themes';
import OnBoardingBottomArrow from './components/OnBoardingBottomArrow';
import {useNavigate} from 'react-router-dom';
import {mpTrack} from 'services/mixpanel';
import {useSelector} from 'react-redux';
import {selectUser} from 'slices/userSlice';

function OnBoardingUserType() {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const [userTypeData, setUserTypeData] = useState(onBoardingButtonUserType);

  useEffect(() => {
    if (user?.onlyUserType?.length === 0) return;
    userTypeData.map((item, index) => {
      if (user?.onlyUserType?.includes(item?.userData?.userType)) {
        setUserTypeData(prevState => {
          const newState = [...prevState];
          newState[index].disabled = false;
          return newState;
        });
        // item.disabled = false;
      } else {
        setUserTypeData(prevState => {
          const newState = [...prevState];
          newState[index].disabled = true;
          return newState;
        });
      }
    });
  }, []);

  useEffect(() => {
    mpTrack(
      'Web App OnBoarding Welcome Ochy User Type Selection Screen Viewed',
    );
    localStorage.removeItem(REGISTRATION_DATA);
  }, []);

  function handleGoBack() {
    navigate('/');
  }

  return (
    <FadinView>
      <div style={styles.container}>
        <div style={styles.headLineContainer}>
          <TextComponent
            multipleText={createTextObjectReusable(
              onBoardingTranslate('UserTypeHeadLine'),
              {
                variant: H4,
                weight: BOLD,
              },
              {
                variant: H4,
                weight: BOLD,
                style: {textAlign: 'center', color: colors.SECONDARY},
              },
            )}
            center={true}
          />
        </div>
        <div style={styles.buttonList}>
          <OnBoardingButtonList data={onBoardingButtonUserType} />
        </div>
      </div>
      <OnBoardingBottomArrow previous={true} onPress={handleGoBack} />
    </FadinView>
  );
}

const styles = {
  buttonList: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-evenly',
    paddingTop: '5%',
    width: '100%',
  },
  headLineContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '5%',
    width: '100%',
  },
};

export default OnBoardingUserType;

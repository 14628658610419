import React from 'react';

import {EXEMPLE_VIDEO_URL} from 'constant/contentConstant';

import VideoComponent from 'screen/analysis/AnalysisViewDetailsScreen/components/VideoComponent';
import OnBoardingBottomArrow from 'screen/auth/registration/onBoarding/components/OnBoardingBottomArrow';
import {useSpring, animated} from 'react-spring';

function OnBoardingAnalysisContainer({
  children,
  handleNextArrowClicked,
  handlePreviousArrowClicked,
  videoUri = EXEMPLE_VIDEO_URL,
  videoSpeed = 1,
  videoStyle = {},
  step,
  childrenUnderVideo,
}) {
  const videoWidthStyle = useSpring({
    width: step === 0 ? '75%' : '30%',
    config: {
      tension: 0,
      friction: 0,
      duration: 500,
    },
  });

  return (
    <div style={styles.container}>
      <animated.div style={{...styles.videoContainer, ...videoWidthStyle}}>
        <VideoComponent
          video={videoUri}
          speed={videoSpeed}
          loop={true}
          videoStyle={videoStyle}
          controls={false}
        />
        <div style={{}}>{childrenUnderVideo}</div>
      </animated.div>
      {children ? (
        <div
          style={{
            ...styles.contentContainer,
            ...{
              width: step === 3 ? '40%' : '20%',
            },
          }}>
          {children}
        </div>
      ) : null}
      <OnBoardingBottomArrow
        onPress={handlePreviousArrowClicked}
        previous={true}
      />
      <OnBoardingBottomArrow onPress={handleNextArrowClicked} />
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 10,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    marginLeft: 20,
  },
  videoContainer: {
    position: 'sticky',
    marginTop: 20,
    transition: 'width 0.3s ease-in-out', // Fallback for smooth width change
  },
};

export default OnBoardingAnalysisContainer;

import React, {useEffect, useState} from 'react';

import {USER_LOCALE} from 'services/remoteConfig';

import {colors} from 'themes';
import {segments} from '../../constants/onBoardingText';
import {getFootText} from 'utils/textFromAngles';
import AnalysisRightPanelUpdateDetail from 'screen/analysis/AnalysisViewDetailsScreen/components/analysisRightPanelUpdated/AnalysisRightPanelUpdateDetail';
import {getExercisesList} from 'services/uiApi';

function OnBoardingCoachAnalysisSegments({
  title,
  selectedSegment,
  setSelectedSegment,
}) {
  const [exercices, setExercices] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const exercisesList = await getExercisesList();
        setExercices(exercisesList);
      } catch (error) {
        console.error(
          "Une erreur s'est produite lors de la récupération des exercices :",
          error,
        );
      }
    }
    fetchData();
  }, [selectedSegment]);

  if (!selectedSegment) return;
  return (
    <div style={{width: '100%'}}>
      <AnalysisRightPanelUpdateDetail
        displayData={selectedSegment}
        exercices={exercices}
        onBoarding={true}
      />
    </div>
  );
}
const styles = {
  container: {
    height: 1,
    backgroundColor: colors.WHITE_OPACITY_40,
    margin: 'auto',
    marginBottom: 0,
    marginTop: 40,
    width: '95%',
  },
  explanation: {
    color: colors.WHITE_OPACITY_60,
    fontSize: 12,
    textAlign: 'center',
  },
  explanationPerSegment: {
    float: 'right',
    textAlign: 'right',
  },
  roundedScoreContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  scoreContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
  },
  segmentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  textContainer: {
    backgroundColor: colors.BLACK,
    borderRadius: 10,
    margin: 'auto',
    marginTop: 20,
    padding: 10,
    width: '98%',
  },
  yourPosition: {
    color: colors.WHITE_OPACITY_60,
    textAlign: 'right',
  },
};
export default OnBoardingCoachAnalysisSegments;

import TextComponent from 'components/atoms/TextComponent';
import React from 'react';
import { Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { translate } from 'services/remoteConfig';
import { colors } from 'themes';

function OnBoardingProgressGraph() {
  const CustomizedDot = props => {
    const { cx, cy } = props;
    return (
      <svg>
        <circle
          cx={cx}
          cy={cy}
          r="6"
          stroke-width="3"
          fill={colors.SECONDARY}
          stroke={colors.WHITE}
        />
      </svg>
    );
  };

  const data = [
    { x: '0', y: 45 },
    { x: '2', y: 55 },
    { x: '4', y: 53 },
    { x: '6', y: 58 },
    { x: '8', y: 65 },
    { x: '10', y: 64 },
    { x: '12', y: 75 },
    { x: '14', y: 78 },
  ];

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}>

        <div style={styles.chart}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={data}>
              <XAxis
                dataKey="x"
                domain={[0, 15]}
                label={{
                  value: translate('Week'),
                  position: 'insideBottom',
                  offset: 0
                }}
              />
              <YAxis domain={[0, 100]}
                label={{
                  value: translate('TotalScore'),
                  angle: -90,
                  position: 'insideLeft',
                }
                }
              />
              <Line
                type="monotone"
                dataKey="y"
                stroke={colors.SECONDARY}
                dot={<CustomizedDot />}
                strokeWidth={2}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  );
}
const styles = {
  chart: {
    display: 'flex',
    height: '60vh',
    width: '60vh',
  },
};
export default OnBoardingProgressGraph;

import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import { score_colors } from "screen/analysis/AnalysisListCard/constants/analysisListConstants";
import { translate, USER_LOCALE } from "services/remoteConfig";
import { images } from "themes";
import { getScoreImage } from "utils/utils";

export default function PdfInfos({ averageScore, analysisDB, jsonData, brandLogo }) {

    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };
    const averageScoreText =
        averageScore >= 66
            ? 'score_good'
            : averageScore >= 33
                ? 'score_okay'
                : 'score_bad';
    const analysisDate = analysisDB?.date
        ? new Date(analysisDB?.date)?.toLocaleDateString(USER_LOCALE, options)
        : '';

    return (
        <>
            <View
                style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: 20,
                }}>
                <Image source={brandLogo || images.OCHY_LOGO} style={{ height: 50 }} />
                <Text style={styles.title}>{analysisDB?.data?.tag?.name}</Text>
                <Text style={styles.title}>
                    {translate('AnalysisOfDate')} {analysisDate}
                </Text>
            </View>

            <View style={styles.totalScoreContainer}>
                <Image
                    source={jsonData?.thumbnail}
                    style={{ height: 150, width: '60%', borderRadius: 15 }}
                />
                <View
                    style={{ flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
                    <Image
                        style={{ width: 50, height: 50 }}
                        source={getScoreImage(averageScoreText, 'totalScore')}
                    />

                    <View
                        style={{ marginLeft: 15, marginRight: 15, alignItems: 'center' }}>
                        <Text style={{ fontSize: 14 }}>{translate('TotalScore')}</Text>
                        <Text
                            style={{
                                color: score_colors[averageScoreText]?.stroke,
                                backgroundColor:
                                    score_colors[averageScoreText].scoreBackground,
                                padding: 5,
                                borderRadius: 20,
                                textAlign: 'center',
                                alignSelf: 'center',
                                fontSize: 14,
                            }}>
                            {averageScoreText === 'score_bad'
                                ? translate('Bad')
                                : averageScoreText === 'score_okay'
                                    ? translate('Okay')
                                    : translate('Good')}
                        </Text>
                    </View>
                    <Text
                        style={{
                            color: score_colors[averageScoreText]?.stroke,
                            fontSize: 20,
                        }}>
                        {averageScore}%
                    </Text>
                </View>
            </View>
        </>
    )
}


const styles = StyleSheet.create({
    title: {
        fontSize: 20,
    },

    totalScoreContainer: {
        marginBottom: 20,
        alignItems: 'center',
        justifyContent: 'center',
    },
});

import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import VideoComponent from 'screen/analysis/AnalysisViewDetailsScreen/components/VideoComponent';
import CompareAnalysisScreen from './CompareAnalysisScreen';


function ComparePortraitsAnalysis({ firstAnalysis, firstAnalysisData, secondAnalysis, secondAnalysisData }) {
    const location = useLocation();
    const navigate = useNavigate();

    const [firstVideoFlip, setFirstVideoFlip] = useState(false);
    const [secondVideoFlip, setSecondVideoFlip] = useState(false);

    const fistAnalysisValueVideo = firstAnalysis?.videoUrl;
    const secondAnalysisValueVideo = secondAnalysis?.videoUrl;

    function handleVideoFlip(index) {
        if (index === 0) {
            setFirstVideoFlip(!firstVideoFlip);
        } else {
            setSecondVideoFlip(!secondVideoFlip);
        }
    }

    function handleAnalysisPressed(index) {
        navigate(
            `/analysis/${location?.state?.comparedAnalysis[index]?.analysis?.data?.uploadVideoId}`,
            {
                state: {
                    analysis: location?.state?.comparedAnalysis[index]?.analysis,
                    personalised: firstAnalysis?.personalised,
                },
            },
        );
    }
    return (
        <div style={{ display: 'flex', height: '100vh' }}>

            <div style={{ height: '100%', width: '25%' }}>
                <CompareAnalysisScreen
                    analysisData={firstAnalysisData}
                    handleAnalysisPressed={() => {
                        handleAnalysisPressed(0);
                    }}
                    handleVideoFlip={() => {
                        handleVideoFlip(0);
                    }}
                    portraits={true}
                />
            </div>
            <div style={{ width: '25%' }}>
                <VideoComponent
                    video={fistAnalysisValueVideo}
                    flip={firstVideoFlip}
                />
            </div>

            <div style={{ width: '25%' }}>
                <VideoComponent
                    video={secondAnalysisValueVideo}
                    flip={secondVideoFlip}
                />
            </div>
            <div style={{ height: '100%', width: '25%' }}>
                <CompareAnalysisScreen
                    analysisData={secondAnalysisData}
                    handleAnalysisPressed={() => {
                        handleAnalysisPressed(1);
                    }}
                    handleVideoFlip={() => {
                        handleVideoFlip(1);
                    }}
                    portraits={true}
                />
            </div>
        </div>
    );
}

export default ComparePortraitsAnalysis;

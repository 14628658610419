import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import { colors } from "themes";

export default function PdfRunningStyles({ running_styles }) {

    return (
        <View style={styles.section}>
            <View
                style={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                }}>
                <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <Image
                        source={running_styles?.asset}
                        style={{
                            height: 100,
                            borderRadius: 15,
                            backgroundColor: colors.APP_BACKGROUND,
                            padding: 10,
                        }}
                    />
                    <Text style={{ fontSize: 16, marginTop: 10 }}>
                        {running_styles?.animal}
                    </Text>
                </View>
                <View style={{ alignItems: 'center', width: '75%' }}>
                    <Text style={styles.text}>
                        {(running_styles?.animalExplanation || running_styles?.animal_explanation) + '\n' + running_styles?.title + ' : ' + running_styles?.summary}
                    </Text>
                </View>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    text: {
        fontSize: 12,
        marginBottom: 10,
    },
    section: {
        padding: 10,
        marginBottom: 10,
    },
});
import {createSlice} from '@reduxjs/toolkit';
import {CM, EN, FT, IN, KG, LBS} from 'constant/constants';

const initialState = {
  unityType: '',
  height: '',
  heightComplement: '',
  weight: '',
};

export const unitySlice = createSlice({
  name: 'unity',
  initialState,
  reducers: {
    resetUnityStore: (state, action) => {
      state.height = '';
      state.heightComplement = '';
      state.weight = '';
      state.unityType = '';
    },
    setUnity: (state, action) => {
      if (action?.payload === EN) {
        state.height = FT;
        state.heightComplement = IN;
        state.weight = LBS;
      } else {
        state.height = CM;
        state.heightComplement = '';
        state.weight = KG;
      }
    },
    setUnityType: (state, action) => {
      state.unityType = action?.payload;
    },
    setHeightUnity: (state, action) => {
      state.height = action?.payload;
      state.heightComplement = IN;
    },
    setWeightUnity: (state, action) => {
      state.weight = action?.payload;
    },
  },
});

export const {
  resetUnityStore,
  setUnity,
  setUnityType,
  setHeightUnity,
  setWeightUnity,
} = unitySlice.actions;

export const selectHeightUnity = state => state?.unity?.height;
export const selectHeighComplementUnity = state =>
  state?.unity?.heightComplement;
export const selectWeightUnity = state => state?.unity?.weight;
export const selectUnityType = state => state?.unity?.unityType;

export default unitySlice.reducer;

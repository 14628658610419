import {images} from 'themes';
import {auth} from './firebaseInit';
import {store} from 'store';
import {
  keyMapping,
  scoreMapping,
  segment_ponderation,
} from 'screen/analysis/AnalysisListCard/constants/analysisListConstants';
import {NONE, USERTYPE_ATHLETE} from 'constant/constants';
import {USER_LOCALE} from 'services/remoteConfig';

const GENDER_PICTURE = {
  woman: images.WOMAN_ATHLETE_DEFAULT_PICTURE,
  man: images.MAN_ATHLETE_DEFAULT_PICTURE,
  coach: images.COACH_DEFAULT_PICTURE,
  pro: images.PROFESSIONAL_DEFAULT_PICTURE,
};

export function isOwnerOfItem(id) {
  if (auth) {
    const userId = auth?.currentUser?.uid;
    if (userId === id) return true;
    return false;
  }
}

export function checkUserPermission(autorizedUserType) {
  const state = store?.getState();
  if (autorizedUserType?.includes(state?.user?.userType)) {
    return true;
  }
  return false;
}

export function isPro() {
  return checkUserPermission(['professional']);
}

export function isCoach() {
  return checkUserPermission(['coach']);
}

export function isWoman() {
  const state = store.getState();
  if (state?.user?.sGender === 'woman') return true;
  return false;
}

export function getHeightinCM(height) {
  if (!height) return 0;
  const heightInCM = height * 100;
  return heightInCM?.toFixed(0);
}

export function getHeightinMeters(height) {
  if (!height) return 0;
  const heightInMeters = height / 100;
  return heightInMeters.toFixed(2);
}

export function isValidNumber(text) {
  const reg = new RegExp('^[0-9]+$');
  return reg.test(text);
}

export function createTextObjectReusable(
  text,
  styleDefault,
  styleSQBracket,
  styleBracket,
) {
  if (!text) return;
  const pattern = /{{([^{}]+)}}|\[\[([^[\]]+)]]/g;
  const matches = [...text.matchAll(pattern)]; // Use matchAll to get all matches
  const result = [];
  let lastIndex = 0;
  for (const match of matches) {
    const [fullMatch, sQBracket, bracket] = match;
    const normalText = text.slice(lastIndex, match.index);
    // Add normal text to the result as an object
    if (normalText.trim() !== '') {
      result.push({text: normalText});
    }
    // Add orangeText to the result as an object with the “orangeText” key
    if (sQBracket) {
      result.push({sQBracket});
    }
    // Add boldText to the result as an object with the “boldText” key
    if (bracket) {
      result.push({bracket});
    }
    lastIndex = match.index + fullMatch.length;
  }
  // Add any remaining text after the last match
  const remainingText = text.slice(lastIndex);
  if (remainingText.trim() !== '') {
    result.push({text: remainingText});
  }
  const data = [];
  result?.map(element => {
    if (element?.text) {
      data.push({
        text: element?.text,
        weight: styleDefault?.weight,
        variant: styleDefault?.variant,
        style: styleDefault?.style,
      });
    }
    if (element?.sQBracket) {
      data.push({
        text: element?.sQBracket,
        weight: styleSQBracket?.weight,
        variant: styleSQBracket?.variant,
        style: styleSQBracket?.style,
      });
    }
    if (element?.bracket) {
      data.push({
        text: element?.bracket,
        weight: styleBracket?.weight,
        variant: styleBracket?.variant,
        style: styleBracket?.style,
      });
    }
  });
  return data;
}

export function canUserUseAnalysis(usage) {
  if (checkUserPermission([USERTYPE_ATHLETE])) return true;
  if (usage?.creditNumber > 0 || usage?.monthlyCreditNumber > 0) return true;

  //legacy unlimited plan on yearly for coaches / professionals
  if (
    usage?.yearlyPlan === true &&
    usage?.yearly10 === false &&
    usage?.yearly30 === false
  )
    return true;

  //legacy unlimited plan on monthly for coaches / professionals
  if (
    usage?.monthlyPlan === true &&
    usage?.monthly10 === false &&
    usage?.monthly30 === false
  )
    return true;

  return false;
}

export function getURLFile(e) {
  e.preventDefault();
  if (e?.target?.files && e?.target?.files[0]) {
    const file = e?.target?.files[0];
    const url = URL.createObjectURL(file);
    if (file?.size > 15 * 1024000) {
      return;
    }
    return url;
  }
}

export function userProfilePic(userPhoto, gender) {
  if (userPhoto) {
    return userPhoto;
  } else {
    if (checkUserPermission(['athlete']) && gender === 'woman') {
      return GENDER_PICTURE.woman;
    } else if (checkUserPermission(['athlete'])) {
      return GENDER_PICTURE.man;
    } else if (checkUserPermission(['coach'])) {
      return GENDER_PICTURE.coach;
    } else if (checkUserPermission(['professional'])) {
      return GENDER_PICTURE.pro;
    }
  }
}

export function getAverageScore(content) {
  if (!content) return;
  const totalScores = content?.reduce((sum, entry) => {
    if (entry?.length > 0 && entry[1]?.score_number) {
      return sum + entry[1]?.score_number;
    }
    return sum;
  }, 0);

  const validEntriesCount = content?.filter(
    entry => entry?.length > 0 && entry[1]?.score_number,
  )?.length;
  if (!validEntriesCount) {
    return '0';
  }
  return ((totalScores / validEntriesCount) * 100)?.toFixed(0)?.toString();
}

export function getLanguageCode(languageValue) {
  if (languageValue === 'Français') {
    return 'fr';
  } else if (languageValue === '中文') {
    return 'zh';
  } else if (languageValue === 'Deutsch') {
    return 'de';
  } else if (languageValue === 'Español') {
    return 'es';
  } else if (languageValue === 'Italiano') {
    return 'it';
  } else if (languageValue === 'Português') {
    return 'pt';
  } else return 'en';
}

export function formatDate(jsonDate) {
  if (jsonDate === null || jsonDate === undefined) return null;

  try {
    const date = new Date(jsonDate);

    const timestampWithOffset = date?.getTime();
    const offset = date?.getTimezoneOffset() * 60 * 1000;
    const timestampWithoutOffset = timestampWithOffset - offset;
    const dateWithoutOffset = new Date(timestampWithoutOffset);
    let options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    return dateWithoutOffset?.toLocaleDateString(USER_LOCALE, options);
  } catch (error) {
    console.log('Error in format date :', error);
  }
}

export function prioritySegmentToWork(content) {
  const results = [];
  if (!content) return;
  for (const [segment, info] of Object.entries(content)) {
    // Map the segment to its corresponding key if available
    const mappedSegment = keyMapping[segment] || segment;
    if (mappedSegment !== 'foot' && info?.texts?.score) {
      if (!info?.score_number || info?.score_number < 0.9) {
        const baseScore = scoreMapping[info.texts.score] || 0;
        const ponderation = segment_ponderation[mappedSegment] || 1;
        const totalScore = baseScore * ponderation;
        if (totalScore > 0) results.push({segment: mappedSegment, totalScore});
      }
    }
  }

  // Sort and keep only the first 3 elements
  return results
    ?.sort(
      (a, b) =>
        b.totalScore - a.totalScore || a.segment.localeCompare(b.segment),
    )
    ?.slice(0, 3);
}

export function getBestSegment(content) {
  if (!content) return;

  let highestScore = 0;
  let highestScoreObject = null;
  const keyMappings = {
    head: 'RightEar',
    trunk: 'RightHip',
    arm: 'RightElbow',
    forwardCycle: 'RightKnee',
    backCycle: 'LeftKnee',
    foot: 'RightAnkle',
  };

  for (const key in content) {
    if (content.hasOwnProperty(key)) {
      const obj = content[key];
      // Check if the current object has a score_number property and if it's higher than the current highest score
      if (
        obj &&
        obj.hasOwnProperty('score_number') &&
        obj.score_number > highestScore
      ) {
        // Update the highest score and corresponding object
        highestScore = obj.score_number;
        highestScoreObject = key;
      }
    }
  }

  // Return the corresponding value based on the key, or null if no object with score_number is found
  return keyMappings[highestScoreObject] || null;
}

export function replaceUserNameByActualName(text) {
  if (!text) return;
  if (text.includes('{userName}')) {
    const state = store.getState();
    const userName = state?.user?.fullName;
    return text.replace('{userName}', userName + ' ');
  }
}

export function getKeyName(analysisKey, angle) {
  if (analysisKey === 'HeadG' && toGoodKey(analysisKey) === 'HeadGZ') {
    return 'HeadGZ';
  }
  if (analysisKey === 'HeadBT' && angle <= 0) {
    return 'HeadBTIF';
  }
  if (analysisKey === 'HeadOT' && angle >= 0) {
    return 'HeadOTSZ';
  }
  if (analysisKey === 'TrunkOT' && angle <= 0) {
    return 'TrunkOTIF';
  }
  if (analysisKey === 'TrunkOT' && angle <= 3) {
    return 'TrunkOTC';
  }
  if (analysisKey === 'TrunkBT' && angle >= -5) {
    return 'TrunkBTZ';
  }
  if (analysisKey === 'TrunkBT' && angle <= 0) {
    return 'TrunkBTIF';
  }
  return analysisKey;
}

export function toGoodKey(keyName, angle) {
  if (keyName === 'HeadG' && angle >= 0) {
    return 'HeadGZ';
  }
  if (keyName?.endsWith('G')) {
    return keyName;
  }
  let base = '';
  if (keyName?.endsWith('O') || keyName?.endsWith('B')) {
    base = keyName?.slice(0, -1);
  } else {
    base = keyName?.slice(0, -2);
  }
  return `${base}G`;
}

export function getScoreImage(score, type) {
  if (type === 'foot' && score === 'score_good')
    return 'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2Fimages%2FmailCards%2FimageFootGoot.png?alt=media&token=ec6043b6-61f0-4ae1-bde6-f1dcee184d86';
  else if (type === 'foot' && score === 'score_okay') {
    return 'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2Fimages%2FmailCards%2FimageFootOkay.png?alt=media&token=d0d96716-bbef-4cf9-895e-ae46f20d3e04';
  } else if (type === 'foot' && score === 'score_bad') {
    return 'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2Fimages%2FmailCards%2FimageFootBad.png?alt=media&token=72e2533e-84c3-4702-95a3-abccef345c7d';
  } else if (type === 'head' && score === 'score_good')
    return 'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2Fimages%2FmailCards%2FimageHeadGood.png?alt=media&token=0893f170-2ff9-43e8-acbb-c5f63a0a222d';
  else if (type === 'head' && score === 'score_okay') {
    return 'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2Fimages%2FmailCards%2FimageHeadOkay.png?alt=media&token=51229f5b-7c71-455b-9e55-f14ea0131206';
  } else if (type === 'head' && score === 'score_bad') {
    return 'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2Fimages%2FmailCards%2FimageHeadBad.png?alt=media&token=6cb56754-66e0-4e9b-b43e-e9a64d8eb845';
  } else if (type === 'back' && score === 'score_good')
    return 'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2Fimages%2FmailCards%2FimageBackGood.png?alt=media&token=089101fd-584e-44de-b533-374584b48e2d';
  else if (type === 'back' && score === 'score_okay') {
    return 'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2Fimages%2FmailCards%2FimageBackOkay.png?alt=media&token=8bef934a-eb38-4ec7-9b3b-4cdbf09b6edb';
  } else if (type === 'back' && score === 'score_bad') {
    return 'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2Fimages%2FmailCards%2FimageBackBad.png?alt=media&token=ec7da264-60f1-4a95-b2ea-fe20ddeb6d7e';
  } else if (type === 'arm' && score === 'score_good')
    return 'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2Fimages%2FmailCards%2FimageArmsGood.png?alt=media&token=1adc551f-52cb-4810-8685-b504d41ad039';
  else if (type === 'arm' && score === 'score_okay') {
    return 'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2Fimages%2FmailCards%2FimageArmsOkay.png?alt=media&token=a236ef3c-ea63-4dcb-9018-f4eb155a8002';
  } else if (type === 'arm' && score === 'score_bad') {
    return 'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2Fimages%2FmailCards%2FimageArmsBad.png?alt=media&token=b91c34fd-2064-44c6-8eae-db0a182f99b5';
  } else if (type === 'frontLeg' && score === 'score_good')
    return 'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2Fimages%2FmailCards%2FimageFrontLegGood.png?alt=media&token=ed6d6c75-1e4e-49ee-881b-4e763fe65d01';
  else if (type === 'frontLeg' && score === 'score_okay') {
    return 'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2Fimages%2FmailCards%2FimageFrontLegOkay.png?alt=media&token=b0fda9ff-f851-45d9-be50-06294d9dd297';
  } else if (type === 'frontLeg' && score === 'score_bad') {
    return 'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2Fimages%2FmailCards%2FimageFrontLegBad.png?alt=media&token=396f756d-5ca7-48c2-8abd-cf274a85ffeb';
  } else if (type === 'backLeg' && score === 'score_good')
    return 'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2Fimages%2FmailCards%2FimageBackLegGood.png?alt=media&token=b8207f3e-8107-448e-9d09-c35bfab3e624';
  else if (type === 'backLeg' && score === 'score_okay') {
    return 'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2Fimages%2FmailCards%2FimageBackLegOkay.png?alt=media&token=60e84cc6-9999-40ee-a1ae-f5daaf10dd04';
  } else if (type === 'backLeg' && score === 'score_bad') {
    return 'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2Fimages%2FmailCards%2FimageBackLegBad.png?alt=media&token=6841351f-4d57-4880-bc64-e3298806204b';
  } else if (type === 'totalScore' && score === 'score_good') {
    return 'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2Fhtml_template%2Fimages%2FgreenTrophy.png?alt=media&token=97b1ff02-ad67-48b0-b8a9-aea58c5d815d';
  } else if (type === 'totalScore' && score === 'score_okay') {
    return 'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2Fhtml_template%2Fimages%2FyellowTrophy.png?alt=media&token=a0739a4a-209a-4a3a-9147-74e9bfcb54e3';
  } else if (type === 'totalScore' && score === 'score_bad') {
    return 'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2Fhtml_template%2Fimages%2FredTrophy.png?alt=media&token=42a8eeb4-b65d-4209-99cc-ecd410d428ce';
  }
}

export function determineInvalidElementToExport(jsonData, category, item) {
  switch (category) {
    case 'Metrics':
      if (item === 'stride_length') {
        return parseFloat(jsonData?.metrics?.step_length_meter) < 0;
      }
      return parseFloat(jsonData?.metrics?.[item]) < 0;
    case 'Other':
      if (item === 'joint_angles') {
        return !jsonData?.joint_angles || jsonData?.joint_angles?.side === NONE;
      } else if (item === 'running_styles') {
        return jsonData?.running_styles?.length < 1;
      }
      break;
    default:
      return false;
  }
}

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getDowloadUrlFromGsUrl } from 'services/analysisApi';
import { fetchData } from 'services/jsonApi';
import { useSelector } from 'react-redux';
import PronationAnalysis from './components/pronationAnalysis/PronationAnalysis';
import RunningAnalysis from './RunningAnalysis';
import { useAnalysisListener } from 'hooks/useAnalysisChanges';
import ButtonComponent from 'components/atoms/ButtonComponent';
import { translate } from 'services/remoteConfig';
import { ORANGE } from 'constant/constants';
import { colors } from 'themes';
import TextComponent from 'components/atoms/TextComponent';
import { isMobile } from 'react-device-detect';
import { buildLink } from 'services/mail';
import { extractJson } from 'utils/jsonAdaptater';

function AnalysisShared() {
  const location = useLocation();
  const path = location.pathname;
  const parts = path.split('/');
  const analysisId = parts[parts?.length - 1];

  const userSlice = useSelector(state => state?.user);

  const { analysis } = useAnalysisListener(analysisId);

  const [jsonData, setJsonData] = useState(null);

  useEffect(() => {
    const setJsonData = async () => {
      if (!analysis) return;
      const data = await getDowloadUrlFromGsUrl(analysis?.analysis_url);
      const videoData = await getDowloadUrlFromGsUrl(analysis?.video_url);
      return { jsonUri: data, videoUri: videoData };
    };
    setJsonData().then(res => {

      fetchDataFromUriAndSetState(res);
    });
  }, [analysis]);

  async function fetchDataFromUriAndSetState(uri) {
    const fetchedData = await fetchData(uri?.jsonUri);
    if (!fetchedData) return;
    let data = null;
    if (analysis?.analysis_type === 'pronation') {
      data = fetchedData;
    } else {
      data = extractJson(fetchedData);
    }
    data.videoUrl = uri?.videoUri;
    setJsonData(data);
  }


  if (!jsonData || !analysis) return <></>;

  return (
    <>
      {userSlice?.registered === false ? (
        <div style={styles.container}>
          {isMobile && (
            <div style={styles.textContainer}>
              <TextComponent
                text={translate('ViewMobileApp')}
                sx={{ marginRight: 1 }}
              />
              <ButtonComponent
                onClick={() => {
                  buildLink(analysisId).then(res => {
                    window.location.replace(res);
                  });
                }}
                type={ORANGE}
                title={translate('GoInApp')}
              />
            </div>
          )}
        </div>
      ) : null}
      <div
        style={{
          height: 160,
          width: '100%',
          backgroundColor: 'black',
          position: 'relative',
        }}
      />
      {analysis?.analysisType === 'pronation' ? (
        <PronationAnalysis data={jsonData} />
      ) : (
        <RunningAnalysis jsonData={jsonData} shared={true} />
      )}
    </>

  );
}
const styles = {
  buttonContainer: {
    display: 'flex',
    float: 'right',
    alignItems: 'center',
    height: '100%',
    marginRight: 20,
  },
  container: {
    width: '95%',
    backgroundColor: colors.BACKGROUND,
    zIndex: 10,
    position: 'fixed',
    paddingTop: 20,
    borderRadius: 10,
    marginBottom: 50,
    top: 10,
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
  textContainer: {
    display: 'flex',
    float: 'left',
    alignItems: 'center',
    height: '100%',
    marginLeft: 20,
    paddingTop: 10,
    marginBottom: 20,
  },
};

export default AnalysisShared;

import React from 'react';
import JointAngles from 'screen/analysis/AnalysisViewDetailsScreen/components/JointAngles';
import {jointAngles} from '../../constants/onBoardingText';
import TextComponent from 'components/atoms/TextComponent';
import {createTextObjectReusable} from 'utils/utils';
import {onBoardingTranslate} from 'services/remoteConfig';
import {colors} from 'themes';
import {H5, LANDSCAPE, PORTRAIT, RIGHT} from 'constant/constants';

function OnBoardingCoachGraphs() {
  return (
    <>
      <div style={{textAlign: 'center', marginTop: 10, marginBottom: 20}}>
        <TextComponent
          multipleText={createTextObjectReusable(
            onBoardingTranslate('OnBoardingGraphTitle'),
            {style: {textAlign: 'center'}, variant: H5},
            {style: {color: colors?.SECONDARY}, variant: H5},
          )}
        />
      </div>
      <JointAngles
        jointAngles={jointAngles}
        direction={RIGHT}
        videoOrientation={PORTRAIT}
      />
    </>
  );
}

export default OnBoardingCoachGraphs;

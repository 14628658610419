import {TREADMILL_EXEMPLE_VIDEO_URL} from 'constant/contentConstant';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import VideoComponent from 'screen/analysis/AnalysisViewDetailsScreen/components/VideoComponent';
import OnBoardingBottomArrow from '../components/OnBoardingBottomArrow';
import TextComponent from 'components/atoms/TextComponent';
import {onBoardingTranslate, translate} from 'services/remoteConfig';
import {createTextObjectReusable} from 'utils/utils';
import {colors} from 'themes';
import {BOLD, H4, H5} from 'constant/constants';

function OnBoardingCoachTreadmillPresentation() {
  const navigate = useNavigate();

  function handleArrowClicked() {
    navigate('/registration/good-video-tutorial');
  }

  function handlePreviousArrowClicked() {
    navigate('/registration/coach/share');
  }

  return (
    <div style={{display: 'flex'}}>
      <div
        style={{
          width: '65%',
          flexDirection: 'collumn',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <div style={{width: '45vh'}}>
          <VideoComponent video={TREADMILL_EXEMPLE_VIDEO_URL} />
        </div>
        <OnBoardingBottomArrow onPress={handleArrowClicked} />
        <OnBoardingBottomArrow
          onPress={handlePreviousArrowClicked}
          previous={true}
        />
      </div>
      <div style={{textAlign: 'center', width: '35%'}}>
        <TextComponent
          text={translate('TreadmillAnalysis')}
          variant={H4}
          weight={BOLD}
          sx={{marginTop: 2, marginBottom: 2}}
        />
        <TextComponent
          multipleText={createTextObjectReusable(
            onBoardingTranslate('TreadmillAnalysisDiscover'),
            {
              style: {
                color: colors.WHITE,
                textAlign: 'center',
              },
              variant: H5,
            },
            {
              variant: H5,
              style: {color: colors.SECONDARY, textAlign: 'center'},
            },
            {},
          )}
        />
      </div>
    </div>
  );
}

export default OnBoardingCoachTreadmillPresentation;

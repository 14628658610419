import React, {useEffect, useState} from 'react';

import {useNavigate} from 'react-router-dom';

import OnBoardingBottomArrow from './components/OnBoardingBottomArrow';
import TextComponent from 'components/atoms/TextComponent';

import {getGoodViedeoTutorial} from 'services/uiApi';
import {onBoardingTranslate, translate} from 'services/remoteConfig';
import {mpTrack} from 'services/mixpanel';

import {
  createTextObjectReusable,
  replaceUserNameByActualName,
} from 'utils/utils';

import {
  ATHLETE,
  BODY_1,
  BOLD,
  COACH,
  H5,
  REGISTRATION_DATA,
} from 'constant/constants';
import {tutoVideoIntro} from './constants/onBoardingText';

import {colors} from 'themes';
import {videoTutosContent, videoTutoTitle} from './components/VideoTutosData';

const MP4 = 'video/mp4';

function OnBoardingGoodVideo() {
  const navigate = useNavigate();
  const [videoUri, setVideoUri] = useState('');

  useEffect(() => {
    mpTrack('Web App OnBoarding Good Video Tuto Screen Viewed');
    getVideoUri();
  }, []);

  async function getVideoUri() {
    const uri = await getGoodViedeoTutorial();
    if (!uri) return;
    setVideoUri(uri);
  }

  const userType = JSON.parse(
    localStorage.getItem(REGISTRATION_DATA),
  )?.userType;

  function handleArrowClicked() {
    navigate('/registration/profile-creation');
  }

  function handlePreviousArrowClicked() {
    if (userType === ATHLETE)
      navigate('/registration/athlete/analysis-exercises');
    if (userType === COACH) navigate('/registration/coach/treadmill');
    else navigate('/registration/coach/share');
  }

  if (!videoUri) return null;
  return (
    <div style={styles.container}>
      <div style={styles.textContainer}>
        <TextComponent
          multipleText={createTextObjectReusable(
            replaceUserNameByActualName(
              onBoardingTranslate(tutoVideoIntro[userType]),
            ),
            {
              variant: H5,
              weight: BOLD,
            },
            {
              variant: H5,
              weight: BOLD,
              style: {textAlign: 'center', color: colors.SECONDARY},
            },
          )}
          center={true}
        />
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-around',
        }}>
        {videoTutosContent?.map((video, index) => {
          return (
            <div
              style={{
                width: '30%',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}>
              <TextComponent
                text={translate(videoTutoTitle[index])}
                variant={H5}
                sx={{marginBottom: 3, textDecoration: 'underline'}}
              />
              <video
                style={video?.vertical ? styles.video : styles.videoPortrait}
                controls={false}
                loop={true}
                autoPlay={true}
                muted={true}>
                <source src={video.videoUri} type={MP4} />
              </video>
              <div style={{marginTop: 20}}>
                <TextComponent
                  text={translate('Do')}
                  variant={H5}
                  weight={BOLD}
                  sx={{color: colors.VALID_IMAGE}}
                />
                {video.videoTextGood?.map((text, index) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 15,
                      }}>
                      <div
                        style={{
                          width: '15%',
                          display: 'flex',
                          justifyContent: 'center',
                        }}>
                        <div
                          style={{
                            height: 40,
                            width: 40,
                            borderRadius: 25,
                            backgroundColor: colors.GREEN_VALID,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: 10,
                          }}>
                          {text.icon}
                        </div>
                      </div>
                      <div style={{width: '85%'}}>
                        <TextComponent
                          multipleText={createTextObjectReusable(
                            translate(text.text),
                            {
                              style: {color: colors.GREEN_VALID},
                            },
                          )}
                          variant={BODY_1}
                          weight={BOLD}
                        />
                      </div>
                    </div>
                  );
                })}
                <div style={{marginTop: 20}}>
                  <TextComponent
                    text={translate('Dont') + ' ' + translate('Do')}
                    variant={H5}
                    weight={BOLD}
                    sx={{color: colors.ERROR}}
                  />
                  {video.videoTextGood?.map((text, index) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: 15,
                        }}>
                        <div
                          style={{
                            width: '15%',
                            display: 'flex',
                            justifyContent: 'center',
                          }}>
                          <div
                            style={{
                              height: 40,
                              width: 40,
                              borderRadius: 25,
                              backgroundColor: colors.ERROR,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              marginRight: 10,
                            }}>
                            {text.icon}
                          </div>
                        </div>
                        <div style={{width: '85%'}}>
                          <TextComponent
                            multipleText={createTextObjectReusable(
                              translate(text.text),
                              {
                                style: {color: colors.ERROR},
                              },
                            )}
                            variant={BODY_1}
                            weight={BOLD}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <OnBoardingBottomArrow onPress={handleArrowClicked} />
      <OnBoardingBottomArrow
        previous={true}
        onPress={handlePreviousArrowClicked}
      />
    </div>
  );
}
const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '90%',
    margin: 'auto',
  },
  textContainer: {
    width: '50%',
    textAlign: 'center',
    marginBottom: 40,
  },
  video: {
    width: '60%',
  },
  videoPortrait: {
    width: '100%',
  },
};
export default OnBoardingGoodVideo;

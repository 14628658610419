import React, { useEffect, useRef } from 'react';
import './styles/VideoComponent.css';
import { LANDSCAPE, PORTRAIT } from 'constant/constants';

export const VideoComponent = ({
  video,
  controls = true,
  speed = 0.25,
  loop = true,
  flip = false,
  setOrientation,
  videoStyle = {},
}) => {
  const videoRef = useRef();
  const setPlayBack = () => {
    videoRef.current.playbackRate = speed;
  };

  function getVideoOrientation() {
    if (videoRef?.current?.videoHeight > videoRef?.current?.videoWidth) {
      return PORTRAIT;
    }
    return LANDSCAPE;
  }

  useEffect(() => {
    if (flip) {
      videoRef.current.classList.add('video-flip');
    } else {
      videoRef.current.classList.remove('video-flip');
    }
  }, [flip]);

  return (
    <video
      ref={videoRef}
      controls={controls}
      loop={loop}
      onCanPlay={setPlayBack}
      style={{ ...styles.video, ...videoStyle }}
      autoPlay={true}
      onLoadedMetadata={() => {
        if (setOrientation) {
          setOrientation(getVideoOrientation());
        }
      }}
      muted={true}>
      <source src={video} type="video/mp4" />
    </video>
  );
};

const styles = {
  video: {
    width: '100%',
    borderRadius: '10px',
  },
};

export default VideoComponent;

import {
  ADVANCED,
  ATHLETE,
  BEGINNER,
  COACH,
  ELITE,
  INJURED,
  INTERMEDIATE,
  LIFESTYLE,
  MAN,
  PERFORMER,
  PROFESSIONAL,
  WOMAN,
} from 'constant/constants';
import {ReactComponent as GenderMale} from 'assets/svg/genderMale.svg';
import {ReactComponent as GenderWoman} from 'assets/svg/genderOther.svg';
import {ReactComponent as GenderOther} from 'assets/svg/genderWoman.svg';
import {images} from 'themes';

export const onBoardingButtonUserType = [
  {
    path: '/registration/user-number',
    text: 'AsRunner',
    subText: 'ButtonUserTypeAthlete',
    userData: {userType: ATHLETE},
    tracking: 'Web App OnBoarding UserType Athlete Button Clicked',
    attributes: {key: 'User_Type', value: ATHLETE},
  },
  {
    path: '/registration/user-number',
    text: 'AsCoach',
    subText: 'ButtonUserTypeCoach',
    userData: {userType: COACH},
    tracking: 'Web App OnBoarding UserType Coach Button Clicked',
    attributes: {key: 'User_Type', value: COACH},
  },
  {
    path: '/registration/coach/specialization',
    subText: 'AsOther',
    text: 'Other',
    userData: {userType: PROFESSIONAL},
    tracking: 'Web App OnBoarding UserType Professional Button Clicked',
    attributes: {key: 'User_Type', value: PROFESSIONAL},
  },
];
//ATHLETE
export const onBoardingAthleteTypology = [
  {
    path: '/registration/athlete/typology-welcome',
    text: 'ButtonTypologyPerformer',
    userData: {typology: PERFORMER},
    tracking: 'Web App Onboarding Athlete Typology Performer Button Clicked',
    attributes: {key: 'athlete_typology', value: PERFORMER},
  },
  {
    path: '/registration/athlete/typology-welcome',
    text: 'ButtonTypologyLifestyle',
    userData: {typology: LIFESTYLE},
    tracking: 'Web App Onboarding Athlete Typology LifeStyle Button Clicked',
    attributes: {key: 'athlete_typology', value: LIFESTYLE},
  },
  {
    path: '/registration/athlete/typology-welcome',
    text: 'ButtonTypologyInjured',
    userData: {typology: INJURED},
    tracking: 'Web App Onboarding Athlete Typology Injured Button Clicked',
    attributes: {key: 'athlete_typology', value: INJURED},
  },
];

export const onBoardingAthleteButtonGender = [
  {
    text: 'Men',
    icon: GenderMale,
    userData: {gender: MAN},
    path: '/registration/athlete/running-level',
    tracking: 'Web App OnBoarding Gender Button Man Clicked',
    attributes: {key: 'athlete_gender', value: MAN},
  },
  {
    text: 'Women',
    icon: GenderWoman,
    userData: {gender: WOMAN},
    path: '/registration/athlete/running-level',
    tracking: 'Web App OnBoarding Gender Button Women Clicked',
    attributes: {key: 'athlete_gender', value: WOMAN},
  },
  {
    text: 'Other',
    icon: GenderOther,
    userData: {gender: MAN},
    path: '/registration/athlete/running-level',
    tracking: 'Web App OnBoarding Gender Button Other Clicked',
    attributes: {key: 'athlete_gender', value: MAN},
  },
];

export const onBoardingButtonRunningLevelMan = [
  {
    text: 'ButtonLevelBeginner',
    subText: 'ButtonLevelBeginnerSubtitle',
    userData: {level: BEGINNER},
    path: '/registration/athlete/running-frequency',
    tracking: 'Web App OnBoarding Level Button Beginner Clicked',
    attributes: {key: 'athlete_running_level', value: BEGINNER},
  },
  {
    text: 'ButtonLevelIntermediate',
    subText: 'ButtonLevelIntermediateSubtitle',
    path: '/registration/athlete/running-frequency',
    userData: {level: INTERMEDIATE},
    tracking: 'Web App OnBoarding Level Button Intermediate Clicked',
    attributes: {key: 'athlete_running_level', value: INTERMEDIATE},
  },
  {
    text: 'ButtonLevelAdvanced',
    subText: 'ButtonLevelAdvancedSubtitle',
    path: '/registration/athlete/running-frequency',
    userData: {level: ADVANCED},
    tracking: 'Web App OnBoarding Level Button Advanced Clicked',
    attributes: {key: 'athlete_running_level', value: ADVANCED},
  },
  {
    text: 'ButtonLevelElite',
    subText: 'ButtonLevelEliteSubtitle',
    path: '/registration/athlete/running-frequency',
    userData: {level: ELITE},
    tracking: 'Web App OnBoarding Level Button Elite Clicked',
    attributes: {key: 'athlete_running_level', value: ELITE},
  },
];

export const onBoardingButtonRunningLevelWoman = [
  {
    text: 'ButtonLevelBeginnerWoman',
    subText: 'ButtonLevelBeginnerSubtitle',
    progress: 0.25,
    userData: {level: BEGINNER},
    path: '/registration/athlete/running-frequency',
    tracking: 'Web App OnBoarding Level Button Beginner Clicked',
    attributes: {key: 'athlete_running_level', value: BEGINNER},
  },
  {
    text: 'ButtonLevelIntermediate',
    subText: 'ButtonLevelIntermediateSubtitle',
    progress: 0.5,
    userData: {level: INTERMEDIATE},

    path: '/registration/athlete/running-frequency',
    tracking: 'Web App OnBoarding Level Button Intermediate Clicked',
    attributes: {key: 'athlete_running_level', value: INTERMEDIATE},
  },
  {
    text: 'ButtonLevelAdvancedWoman',
    subText: 'ButtonLevelAdvancedSubtitleWoman',
    progress: 0.75,
    userData: {level: ADVANCED},
    path: '/registration/athlete/running-frequency',
    tracking: 'Web App OnBoarding Level Button Advanced Clicked',
    attributes: {key: 'athlete_running_level', value: ADVANCED},
  },
  {
    text: 'ButtonLevelElite',
    subText: 'ButtonLevelEliteSubtitleWoman',
    progress: 1,
    userData: {level: ELITE},
    path: '/registration/athlete/running-frequency',
    tracking: 'Web App OnBoarding Level Button Elite Clicked',
    attributes: {key: 'athlete_running_level', value: ELITE},
  },
];

export const onBoardingButtonRunningFrequency = [
  {
    text: 'ButtonFrequency1',
    userData: {timePerWeek: 0},
    path: '/registration/athlete/information',
    tracking: 'Web App OnBoarding Running Frequency OneOrLess Button Clicked',
    attributes: {key: 'athlete_running_frequency', value: '0'},
  },
  {
    text: '1',
    userData: {timePerWeek: 1},
    path: '/registration/athlete/information',
    tracking: 'Web App OnBoarding Running Frequency One Button Clicked',
    attributes: {key: 'athlete_running_frequency', value: '1'},
  },
  {
    text: 'ButtonFrequency2to3',
    userData: {timePerWeek: 3},
    path: '/registration/athlete/information',
    tracking: 'Web App OnBoarding Running Frequency TwoToThree Button Clicked',
    attributes: {key: 'athlete_running_frequency', value: '3'},
  },
  {
    text: 'ButtonFrequency4to6',
    userData: {timePerWeek: 5},
    path: '/registration/athlete/information',
    tracking: 'Web App OnBoarding Running Frequency FourtoSix Button Clicked',
    attributes: {key: 'athlete_running_frequency', value: '5'},
  },
  {
    text: 'ButtonFrequency6more',
    userData: {timePerWeek: 6},
    path: '/registration/athlete/information',
    tracking: 'Web App OnBoarding Running Frequency SixOrMore Button Clicked',
    attributes: {key: 'athlete_running_frequency', value: '6'},
  },
];

//COACH / PRO
export const specializationsByUserType = {
  coach: [
    {
      text: 'LongDistance',
      path: '/registration/coach/place-of-work',
    },
    {
      text: 'MidDistance',
      path: '/registration/coach/place-of-work',
    },
    {
      text: 'RaceWalking',
      path: '/registration/coach/place-of-work',
    },
    {
      text: 'Sprint',
      path: '/registration/coach/place-of-work',
    },
    {
      text: 'Other',
      path: '/registration/coach/place-of-work',
    },
  ],
  professional: [
    {
      text: 'Medecin',
      path: '/registration/coach/analysis',
    },
    {
      text: 'Podiatrist',
      path: '/registration/coach/analysis',
    },
    {
      text: 'Physiotherapist',
      path: '/registration/coach/analysis',
    },
    {
      text: 'Biomechanist',
      path: '/registration/coach/analysis',
    },
    {
      text: 'LaboratoireResearcher',
      path: '/registration/coach/analysis',
    },
    {
      text: 'SportShop',
      path: '/registration/coach/analysis',
    },
    {
      text: 'Other',
      path: '/registration/coach/analysis',
    },
  ],
};

export const placeOfWorkButtons = [
  {
    text: 'ButtonCoachPlacePrivate',
    image: null,
    userData: {typology: 'PersonnalCoach'},
    path: '/registration/coach/athlete-presentation',
    tracking: 'Web App Onboarding Coach Place Of Work Personal Button Clicked',
    attributes: {key: 'coach_typology', value: 'PersonnalCoach'},
  },
  {
    text: 'ButtonCoachPlaceClub',
    image: null,
    path: '/registration/coach/athlete-presentation',
    userData: {typology: 'ClubCoach'},
    tracking: 'Web App Onboarding Coach Place Of Work Club Button Clicked',
    attributes: {key: 'coach_typology', value: 'ClubCoach'},
  },
  {
    text: 'ButtonCoachPlaceSchool',
    image: null,
    path: '/registration/coach/athlete-presentation',
    userData: {typology: 'SchoolCoach'},
    tracking: 'Web App Onboarding Coach Place Of Work School Button Clicked',
    attributes: {key: 'coach_typology', value: 'SchoolCoach'},
  },
];

export const metricData = [
  {
    value: 0.175,
    metricKey: 'time_between_steps',
    image: images.METRICS_TIME_STEPS,
  },
  {
    value: 0.19,
    metricKey: 'ground_contact_time',
    image: images.METRICS_GROUND_CONTACT_TIME,
  },

  {
    value: 163,
    metricKey: 'steps_per_min',
    image: images.METRICS_NUMBER_STEPS,
  },
  {
    value: 0.6,
    metricKey: 'duty_factor',
  },
  {
    value: 2.64,
    metricKey: 'stride_length',
  },
  {
    value: 1.32,
    metricKey: 'step_length',
  },
];

import React from 'react';

import {Line, LineChart, ResponsiveContainer, XAxis, YAxis} from 'recharts';

import {translate} from 'services/remoteConfig';

import {colors} from 'themes';

function JointAnglesChart({data}) {
  console.log(data);
  return (
    <div style={styles.chart}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart margin={{top: 10, right: 10, left: 10, bottom: 40}}>
          <XAxis
            unit="%"
            dataKey="x"
            domain={[0, 100]}
            type="number"
            allowDuplicatedCategory={false}
            stroke={colors.TEXT_GRAY}
            tickLine={{stroke: colors.TEXT_GRAY}}
            label={{
              value: translate('RunningCycle'),
              position: 'insideBottom',
              offset: -10,
            }}
            padding={{left: 10, right: 10}}
          />
          <YAxis
            unit="°"
            stroke={colors.TEXT_GRAY}
            label={{
              value: translate('AngleInDegrees'),
              angle: -90,
              position: 'insideLeft',
              offset: 10,
            }}
            padding={{top: 20, bottom: 20}}
          />

          {data?.map(
            (line, index) =>
              line?.selected &&
              !line?.disabled && (
                <Line
                  key={index}
                  type="monotone"
                  data={line?.data}
                  dataKey="y"
                  stroke={line?.color}
                  strokeWidth={2}
                  dot={false}
                  hide={!line?.selected}
                  isAnimationActive={false}
                />
              ),
          )}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

const styles = {
  chart: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
};

export default JointAnglesChart;

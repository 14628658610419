import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import { BODY_PARTS } from "constant/constants";
import { translate } from "services/remoteConfig";

export default function PdfGraph({ jointAnglesData }) {

    return (
        <View style={styles.section}>
            <View
                style={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    width: '100%',
                }}>
                <Image
                    source={{ uri: jointAnglesData?.chart }}
                    style={{ objectFit: 'cover', width: '60%' }}
                />
                <View style={{ width: '40%' }}>
                    <View style={{ flexDirection: 'row', marginBottom: 10 }}>
                        <Text
                            style={{ fontSize: 12, width: '50%', textAlign: 'center' }}>
                            {translate('JointAngles')}
                        </Text>
                        <Text
                            style={{ fontSize: 12, width: '25%', textAlign: 'center' }}>
                            {translate('Left')}
                        </Text>
                        <Text
                            style={{ fontSize: 12, width: '25%', textAlign: 'center' }}>
                            {translate('Right')}
                        </Text>
                    </View>
                    {jointAnglesData?.data?.map((item, index) => {
                        if (index % 2 === 1) return null;

                        // S'assure qu'au moins un des deux éléments est sélectionné
                        if (
                            !item?.selected &&
                            !jointAnglesData?.data[index + 1]?.selected
                        )
                            return null;

                        return (
                            <View
                                key={index}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    marginBottom: 10,
                                }}>
                                <View
                                    style={{
                                        padding: 10,
                                        width: '50%',
                                        textAlign: 'center',
                                    }}>
                                    <Text style={{ fontSize: 12 }}>
                                        {translate(BODY_PARTS[Math.floor(index / 2)])}
                                    </Text>
                                </View>
                                {item?.selected ? (
                                    <View
                                        style={{
                                            width: '20%',
                                            height: 22,
                                            margin: '0 5px',
                                            lineHeight: '22px',
                                            borderWidth: 1,
                                            borderStyle: 'solid',
                                            borderColor: 'black',
                                            backgroundColor: item?.color,
                                        }}
                                    />
                                ) : (
                                    <Text
                                        style={{
                                            textAlign: 'center',
                                            fontSize: 12,
                                            color: 'red',
                                            width: '20%',
                                            fontWeight: 'bold',
                                            margin: '0 5px',
                                        }}>
                                        {'X'}
                                    </Text>
                                )}
                                {jointAnglesData?.data[index + 1]?.selected ? (
                                    <View
                                        style={{
                                            width: '20%',
                                            height: 22,
                                            margin: '0 5px',
                                            lineHeight: '22px',
                                            borderWidth: 1,
                                            borderStyle: 'solid',
                                            borderColor: 'black',
                                            backgroundColor:
                                                jointAnglesData?.data[index + 1]?.color,
                                        }}
                                    />
                                ) : (
                                    <Text
                                        style={{
                                            textAlign: 'center',
                                            fontSize: 12,
                                            color: 'red',
                                            width: '20%',
                                            fontWeight: 'bold',
                                            margin: '0 5px',
                                        }}>
                                        {'X'}
                                    </Text>
                                )}
                            </View>
                        );
                    })}
                </View>
            </View>
        </View>
    )

}

const styles = StyleSheet.create({
    section: {
        padding: 10,
        marginBottom: 10,
    },
});
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import TextComponent from "components/atoms/TextComponent";
import TextInput from "components/atoms/TextInput";
import ButtonComponent from "components/atoms/ButtonComponent";

import { uploadFile } from "services/upload";
import { updateUserInDB } from "services/authApi";

import { updateProfileStore } from "slices/userSlice";

import { ORANGE } from "constant/constants";

import { colors } from "themes";

import { ReactComponent as AddPhotos } from "assets/svg/addPhotos.svg";
import { translate } from "services/remoteConfig";
import { getURLFile } from "utils/utils";

function UpdateBrand({ source, setSource, setModify }) {

    const [brandName, setBrandName] = useState(false)
    const [disabled, setDisabled] = useState(true)
    const userSlice = useSelector((state) => state?.user);
    const dispatch = useDispatch();

    useEffect(() => {
        if (brandName || source) {
            setDisabled(false)
        }
        else {
            setDisabled(true)
        }
    }, [brandName, source])




    function handleBrandNameChange(e) {
        setBrandName(e.target.value)
    }

    async function handleValidate() {
        if (!brandName || brandName === '' || !source) {
            return;
        }
        const dbURL = await uploadFile(source, undefined, undefined, undefined, 'brand-logo', 'image', userSlice?.userID);
        if (dbURL) {
            setModify(false);
            dispatch(updateProfileStore({ brandName: brandName, brandLogo: dbURL }))
            updateUserInDB({ brandName: brandName, brandLogo: dbURL })
        }
    }

    const handleChange = function (e) {
        setSource(getURLFile(e));
    };

    return (
        <div style={styles.container}>
            <div style={styles.imageContainer} onClick={() => document.getElementById('brandLogo').click()}>
                <input type="file" id="brandLogo" name="brandLogo" accept="image/png, image/jpeg, image/svg" style={styles.imageInput} onChange={handleChange} />
                {source ? (
                    <img style={styles.image} src={source} alt="brandLogo" />
                ) : (
                    <>
                        <AddPhotos height={'10vh'} width={'10vh'} />
                        <TextComponent text={translate('AddLogo')} sx={styles.addLogo} />
                    </>
                )}
            </div>
            <TextInput
                label={translate('StructureName')}
                sx={styles.brandNameInput}
                onChange={handleBrandNameChange}
                image={require("assets/images/company.png")}
            />
            <ButtonComponent title={translate('Apply')} type={ORANGE} sx={styles.button} onClick={handleValidate} disabled={disabled} />
        </div>
    );

}

const styles = {
    addLogo: {
        color: colors.TEXT_GRAY,
        fontSize: '1.1vw'
    },
    brandNameInput: {
        marginTop: '2vh',
        width: '100%',
        height: '10%',
        borderRadius: '2vh',
        backgroundColor: colors.WHITE_OPACITY_20,
        fontSize: '0.8vw'
    },
    button: {
        marginTop: '2vh',
        width: '100%',
        height: '10%',
        borderRadius: '2vh'
    },
    container: {
        width: '90%',
        display: 'flex',
        height: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        flexDirection: 'column',
        alignItems: 'center'
    },
    image: {
        width: '100%',
        height: '100%',
        borderRadius: '2vh'
    },
    imageContainer: {
        cursor: 'pointer',
        width: '100%',
        height: '25vh',
        backgroundColor: colors.APP_BACKGROUND,
        borderRadius: '2vh',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '2vh'
    },
    imageInput: {
        display: 'none'
    }
}


export default UpdateBrand;
import React from 'react';

import {Typography} from '@mui/material';
import {useSprings, animated} from 'react-spring';

import TextComponent from 'components/atoms/TextComponent';

import {onBoardingTranslate, translate} from 'services/remoteConfig';

import {store} from 'store';

import {createTextObjectReusable} from 'utils/utils';

import {
  BODY_1,
  BODY_2,
  BOLD,
  COACH,
  H3,
  H4,
  H5,
  REGISTRATION_DATA,
} from 'constant/constants';

import {colors} from 'themes';
import OnBoardingAnalysisContainer from '../../athleteOnBoarding/analysis/components/OnBoardingAnalysisContainer';
import {useNavigate} from 'react-router-dom';
import {GOOD_EXEMPLE_URL} from 'constant/contentConstant';

function OnBoardingCoachComment() {
  const navigate = useNavigate();

  function handleArrowPressed() {
    navigate('/registration/coach/share');
  }

  function handleBackArrowPressed() {
    navigate('/registration/coach/compare');
  }
  const userType = JSON.parse(
    localStorage.getItem(REGISTRATION_DATA),
  )?.userType;

  const AnimatedText = ({text}) => {
    // Create an array of springs, one for each letter
    const springs = useSprings(
      text.length,
      text.split('').map((_, index) => ({
        from: {opacity: 0, transform: 'translate3d(0,40px,0)'},
        to: {opacity: 1, transform: 'translate3d(0,0px,0)'},
        delay: index * 20, // Stagger the animation
      })),
    );

    return (
      <Typography
        variant={BODY_2}
        color={colors?.WHITE_OPACITY_60}
        component="span">
        {springs.map((props, index) => (
          <animated.span key={index} style={props}>
            {text[index]}
          </animated.span>
        ))}
      </Typography>
    );
  };

  return (
    <OnBoardingAnalysisContainer
      videoUri={GOOD_EXEMPLE_URL}
      handleNextArrowClicked={handleArrowPressed}
      handlePreviousArrowClicked={handleBackArrowPressed}
      childrenUnderVideo={
        <div style={styles.container}>
          <TextComponent
            text={store?.getState()?.user?.fullName}
            sx={{marginBottom: 1}}
          />
          <AnimatedText text={onBoardingTranslate('CoachComment')} />
          <TextComponent
            text={translate('CoachTimeComment')}
            sx={styles.coachTimeComment}
          />
        </div>
      }>
      <div style={{marginTop: 20, marginBottom: 40}}>
        <TextComponent
          text={onBoardingTranslate('WriteNotes')}
          variant={H4}
          weight={BOLD}
          sx={{textAlign: 'center'}}
        />
      </div>
      <TextComponent
        multipleText={createTextObjectReusable(
          onBoardingTranslate('NotesDescription'),
          {
            variant: H5,
          },
          {
            variant: H5,
            style: {textAlign: 'center', color: colors.SECONDARY},
          },
        )}
        variant={H3}
        weight={BOLD}
        center={true}
      />
    </OnBoardingAnalysisContainer>
  );
}
const styles = {
  coachTimeComment: {
    color: colors.WHITE_OPACITY_40,
    fontSize: 12,
    marginTop: 1,
  },
  container: {
    alignSelf: 'center',
    backgroundColor: 'black',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    marginTop: '1vh',
    width: '95%',
  },
};
export default OnBoardingCoachComment;

import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import VideoComponent from 'screen/analysis/AnalysisViewDetailsScreen/components/VideoComponent';
import CompareAnalysisScreen from './CompareAnalysisScreen';


function ComparePortraitsAndLandscapeAnalysis({ portraitAnalysis, portraitAnalysisData, landscapeAnalysis, landscapeAnalysisData }) {
    const location = useLocation();
    const navigate = useNavigate();

    const [firstVideoFlip, setFirstVideoFlip] = useState(false);
    const [secondVideoFlip, setSecondVideoFlip] = useState(false);

    const fistAnalysisValueVideo = portraitAnalysis?.videoUrl;
    const secondAnalysisValueVideo = landscapeAnalysis?.videoUrl;

    function handleVideoFlip(index) {
        if (index === 0) {
            setFirstVideoFlip(!firstVideoFlip);
        } else {
            setSecondVideoFlip(!secondVideoFlip);
        }
    }

    function handleAnalysisPressed(index) {
        navigate(
            `/analysis/${location?.state?.comparedAnalysis[index]?.analysis?.data?.uploadVideoId}`,
            {
                state: {
                    analysis: location?.state?.comparedAnalysis[index]?.analysis,
                },
            },
        );
    }
    return (
        <div style={{ display: 'flex', height: '100vh' }}>

            <div style={{ width: '50%', display: 'flex' }}>

                <div style={{ height: '100%', width: '50%' }}>
                    <CompareAnalysisScreen
                        analysisData={portraitAnalysisData}
                        handleAnalysisPressed={() => {
                            handleAnalysisPressed(0);
                        }}
                        handleVideoFlip={() => {
                            handleVideoFlip(0);
                        }}
                        portraits={true}
                    />
                </div>
                <div style={{ width: '50%' }}>
                    <VideoComponent
                        video={fistAnalysisValueVideo}
                        flip={firstVideoFlip}
                    />
                </div>
            </div>

            <div style={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
                <div style={{ width: '100%' }}>
                    <VideoComponent
                        video={secondAnalysisValueVideo}
                        flip={secondVideoFlip}
                    />
                </div>
                <div style={{ height: '100%', width: '100%' }}>
                    <CompareAnalysisScreen
                        analysisData={landscapeAnalysisData}
                        handleAnalysisPressed={() => {
                            handleAnalysisPressed(1);
                        }}
                        handleVideoFlip={() => {
                            handleVideoFlip(1);
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default ComparePortraitsAndLandscapeAnalysis;

import React from 'react';
import {useNavigate} from 'react-router-dom';
import OnBoardingBottomArrow from '../components/OnBoardingBottomArrow';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import {Tooltip} from '@mui/material';
import {colors} from 'themes';
import TextComponent from 'components/atoms/TextComponent';
import {onBoardingTranslate, translate} from 'services/remoteConfig';
import {BODY_1, H4, H5} from 'constant/constants';
import OnBoardingProgressGraph from '../components/OnBoardingProgressGraph';
import {progressSegments} from '../constants/onBoardingText';
import {createTextObjectReusable} from 'utils/utils';
import RoundedScoreMeter from 'screen/analysis/AnalysisListCard/components/RoundedScoreMeter';
import ScoreEvolution from 'screen/progress/components/ScoreEvolution';

function OnBoardingAtheteProgress() {
  const navigate = useNavigate();

  function handleArrowPressed() {
    navigate('/registration/profile-creation');
  }

  function handleBackArrowPressed() {
    navigate('/registration/athlete/compare');
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '3vh',
      }}>
      <OnBoardingProgressGraph />

      <div
        style={{
          marginTop: 10,
          width: '50%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <TextComponent
          text={translate('Progress')}
          variant={H5}
          sx={{textAlign: 'center', marginTop: 10}}
        />
        <TextComponent
          text={translate('ProgressOnboardingDesc')}
          variant={BODY_1}
          sx={{marginTop: 3, marginLeft: 1}}
        />
      </div>
      <OnBoardingBottomArrow onPress={handleArrowPressed} />
      <OnBoardingBottomArrow onPress={handleBackArrowPressed} previous={true} />
    </div>
  );
}

const styles = {
  chart: {
    display: 'flex',
    height: '80vh',
    width: '80vh',
  },
};

export default OnBoardingAtheteProgress;

import TextComponent from 'components/atoms/TextComponent';
import {BODY_2, BODY_3} from 'constant/constants';
import React from 'react';
import {colors} from 'themes';

function ScoreEvolution({progress, segment}) {
  if (progress > -0.5 && progress < 0.5) {
    return <TextComponent sx={styles.equal} variant={BODY_3} text={'0%'} />;
  }
  return (
    <div
      style={
        segment === 'totalScore'
          ? styles.container
          : progress < 0.5
          ? styles.redContainer
          : styles.greenContainer
      }>
      <div
        style={{
          ...styles.triangle,
          borderBottomColor: progress > 0 ? colors.GREEN : colors.RED,
          ...(progress < 0 && {transform: 'rotate(180deg)', marginTop: 3}),
        }}
      />
      <TextComponent
        text={Math.abs(progress)?.toFixed(0) + '%'}
        variant={BODY_2}
        sx={{
          ...styles.evolutionScore,
          color: progress > 0 ? colors.GREEN : colors.RED,
        }}
      />
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  equal: {
    color: colors.DARK_GRAY,
    marginLeft: 5,
  },
  evolutionScore: {
    marginLeft: 0.5,
  },
  greenContainer: {
    backgroundColor: colors.GREEN_OPACITY_2O,
    borderRadius: 20,
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 6,
  },
  redContainer: {
    backgroundColor: colors.RED_OPACITY_20,
    borderRadius: 20,
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 6,
  },
  triangle: {
    backgroundColor: colors.TRANSPARENT,
    borderBottomWidth: 5,
    borderLeftColor: colors.TRANSPARENT,
    borderLeftWidth: 5,
    borderRightColor: colors.TRANSPARENT,
    borderRightWidth: 5,
    borderStyle: 'solid',
    height: 0,
    width: 0,
  },
};
export default ScoreEvolution;

import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import { translate } from "services/remoteConfig";
import { colors, images } from "themes";

export default function PdfFoot({ footStrikePattern, foot, overstrideValue }) {

    const footAsset =
        footStrikePattern === 'forefoot'
            ? images.FORE_FOOT
            : footStrikePattern === 'midfoot'
                ? images.MID_FOOT
                : images.REAR_FOOT;

    if (!foot?.asset_id) return footLanding(footAsset, foot);
    else return footWithOverstride(foot, overstrideValue);

}

function footLanding(footAsset, foot) {
    return (
        <View style={styles.section}>
            <View
                style={{
                    marginBottom: 10,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                <Image
                    src={footAsset}
                    style={{
                        height: 75,
                        width: '30%',
                        backgroundColor: colors.APP_BACKGROUND,
                        borderRadius: 15,
                        objectFit: 'contain',
                    }}
                />
                <View style={{ width: '60%' }}>
                    <Text
                        style={{
                            fontSize: 16,
                            marginBottom: 5,
                            color: colors.SECONDARY,
                        }}>
                        {foot?.text1}
                    </Text>
                    <Text style={[{ color: colors.PRIMARY, fontSize: 12 }]}>
                        {foot?.text2}
                    </Text>
                </View>
            </View>
        </View>
    )
}

function footWithOverstride(foot, overstrideValue) {

    return (
        <View style={styles.section}>
            <View wrap={false}>
                <Text style={{ fontSize: 16, marginBottom: 10, width: '100%' }}>
                    {foot?.overstrideTitle}
                </Text>
                <View style={{ flexDirection: 'row' }}>
                    <View
                        style={{
                            width: '40%',
                            alignItems: 'center',
                            justifyContent: 'center',

                        }}>
                        <View
                            style={{
                                position: 'relative',
                                width: '100%',
                                height: 150,
                                backgroundColor: colors.APP_BACKGROUND,
                                borderRadius: 20,
                            }}>

                            <Image
                                src={
                                    images[foot?.asset_id]
                                }
                                style={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: 15,
                                    objectFit: 'contain',
                                }}
                            />
                        </View>

                        <Text style={{ marginTop: 10, fontSize: 12 }}>
                            {translate('Angle')} : {overstrideValue}°
                        </Text>
                        <Text style={{ fontSize: 12 }}>
                            {translate('GoodRange')} : 0°{' '}
                            {foot?.rangeGood[1]}°
                        </Text>
                    </View>
                    <View style={{
                        width: '60%', padding: 10,
                    }}>

                        <Text style={{ fontSize: 12, marginBottom: 5, color: colors.PRIMARY }}>
                            {foot?.overstrideExplanation}
                        </Text>

                        <View
                            style={{
                                flexDirection: 'row',
                                alignItems: 'flex-start',
                                marginTop: 10,
                            }}>
                            <Text
                                style={{ fontSize: 12, color: colors.PRIMARY }}>
                                <Text
                                    style={{
                                        color: colors.SECONDARY,
                                        fontSize: 12,
                                    }}>
                                    {foot?.text1 + ' : '}

                                </Text>
                                {foot?.footStrikeExplanation}
                            </Text>
                        </View>

                    </View>
                </View>
            </View>

            <View style={{ marginTop: 10, width: '100%' }}>
                {Object.entries(foot?.phases || {})?.map(
                    ([key, value], i) => (
                        <View
                            key={i}
                            style={{
                                flexDirection: 'row',
                                alignItems: 'flex-start',
                                marginTop: 10,
                            }}>
                            <Text
                                style={{ fontSize: 12, color: colors.PRIMARY }}>
                                <Text
                                    style={{
                                        color: colors.SECONDARY,
                                        fontSize: 12,
                                    }}>
                                    {key === 'impact'
                                        ? translate('Impact')
                                        : key === 'amortisation'
                                            ? translate('Amortisation')
                                            : translate('Propulsion')}{' '}
                                    :{' '}
                                </Text>
                                {value}
                            </Text>
                        </View>
                    ),
                )}
            </View>

        </View>
    )
}

const styles = StyleSheet.create({
    section: {
        padding: 10,
        marginBottom: 10,
    },
});
import {Box, Grid} from '@mui/material';
import TextComponent from 'components/atoms/TextComponent';
import {BODY_2, H5} from 'constant/constants';
import React from 'react';
import {
  onBoardingTranslate,
  translate,
  USER_LOCALE,
} from 'services/remoteConfig';
import {colors} from 'themes';
import {createTextObjectReusable} from 'utils/utils';
import {segments} from '../../constants/onBoardingText';
import RoundedScoreMeter from 'screen/analysis/AnalysisListCard/components/RoundedScoreMeter';
import {mpTrack} from 'services/mixpanel';
import {
  getArmText,
  getBackText,
  getFootText,
  getHeadText,
  getKneeBackText,
  getKneeFrontText,
} from 'utils/textFromAngles';

function OnBoardingCoachSegmentsList({selectedSegment, setSelectedSegment}) {
  function onPress(item) {
    mpTrack('OnBoarding Web App Coach Segment Card Button Clicked');
    if (item?.type === 'RightAnkle') setSelectedSegment(getFootText(item));
    else if (item?.type === 'RightEar')
      setSelectedSegment(getHeadText(item, item?.angle, 5));
    else if (item?.type === 'RightHip')
      setSelectedSegment(getBackText(item, item?.angle, 5));
    else if (item?.type === 'RightElbow')
      setSelectedSegment(getArmText(item, item?.angle, 5));
    else if (item?.type === 'forwardCycle')
      setSelectedSegment(getKneeFrontText(item, item?.angle, 5));
    else if (item?.type === 'backCycle')
      setSelectedSegment(getKneeBackText(item, item?.angle, 5));
  }

  return (
    <div style={{width: '100%'}}>
      <TextComponent
        text={translate('TapToSeeMore')}
        variant={BODY_2}
        sx={{
          textAlign: 'center',
          color: colors.WHITE_OPACITY_30,
        }}
      />
      <Grid container spacing={2} style={{marginTop: 20}}>
        {segments[USER_LOCALE].map((segment, index) => (
          <Grid item xs={8} sm={5} md={4} lg={4} key={index}>
            <Box
              sx={{
                borderRadius: 2,
                paddingBottom: 1,
                backgroundColor:
                  segment?.type === selectedSegment?.type
                    ? colors.WHITE_OPACITY_10
                    : '',
                '&:hover': {
                  backgroundColor: colors.WHITE_OPACITY_10,
                  cursor: 'pointer',
                },
              }}
              onClick={() => {
                onPress(segment);
              }}>
              <div style={styles.roundedScoreContainer}>
                <RoundedScoreMeter
                  scoreNumber={segment?.score_number || null}
                  score_text={segment?.score}
                  item={segment?.type}
                  size={90}
                />
              </div>
              <TextComponent
                text={segment?.texts?.title}
                sx={{textAlign: 'center'}}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
const styles = {
  container2: {
    marginTop: '1vh',
  },
  container: {
    height: 1,
    backgroundColor: colors.WHITE_OPACITY_40,
    margin: 'auto',
    marginBottom: 0,
    marginTop: 40,
    width: '95%',
  },
  explanation: {
    color: colors.WHITE_OPACITY_60,
    fontSize: 12,
    textAlign: 'center',
  },
  explanationPerSegment: {
    float: 'right',
    textAlign: 'right',
  },
  roundedScoreContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  scoreContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
  },
  segmentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  textContainer: {
    backgroundColor: colors.BLACK,
    borderRadius: 10,
    margin: 'auto',
    marginTop: 20,
    padding: 10,
    width: '98%',
  },
  yourPosition: {
    color: colors.WHITE_OPACITY_60,
    textAlign: 'right',
  },
};

export default OnBoardingCoachSegmentsList;

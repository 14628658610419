import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import { translate } from "services/remoteConfig";
import { colors, images } from "themes";
import { getKeyName, toGoodKey } from "utils/utils";

const keyMappings = {
    Head: 'RightEar',
    Trunk: 'RightHip',
    Arm: 'RightElbow',
    ForwardCycle: 'RightKnee',
    BackCycle: 'LeftKnee',
    Foot: 'RightAnkle',
};

export default function PdfSegments({ data }) {
    return (
        <>
            {data?.map((item, index) => (
                <View key={index} style={styles.section} wrap={false}>
                    <View
                        style={{
                            justifyContent: 'space-between',
                            flexDirection: 'row',
                            width: '100%',
                            alignItems: 'center',

                        }}>
                        <Text style={{ fontSize: 16, marginBottom: 10, width: '40%' }}>
                            {translate(
                                Object.keys(keyMappings)?.find(
                                    key => keyMappings[key] === item?.type,
                                ),
                            )}{' '}
                            : {item?.text1}
                        </Text>
                        <View style={styles.card}>
                            <Text style={styles.cardTitle}>{translate('Recap')}</Text>
                            {item?.recap?.map((recap, i) => (
                                <View key={i} style={styles.recapContainer}>
                                    <View style={styles.bulletPoint} />
                                    <Text style={styles.bulletText}>{recap}</Text>
                                </View>
                            ))}
                        </View>
                    </View>
                    <View
                        style={{
                            marginBottom: 10,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>
                        {index % 2 === 0 ? (
                            <>
                                <View style={{ width: '60%' }}>
                                    {Object.entries(item?.segmentedExplanation || {}).map(
                                        ([key, value], i) => (
                                            <View
                                                key={i}
                                                style={{
                                                    flexDirection: 'row',
                                                    alignItems: 'flex-start',
                                                    marginTop: 10,
                                                }}>
                                                <Text
                                                    style={{ fontSize: 12, color: colors.PRIMARY }}>
                                                    <Text
                                                        style={{
                                                            color: colors.SECONDARY,
                                                            fontSize: 12,
                                                        }}>
                                                        {key === 'coach_tips'
                                                            ? translate('CoachTips')
                                                            : key === 'correction'
                                                                ? translate('Correction')
                                                                : translate('Consequences')}{' '}
                                                        :{' '}
                                                    </Text>
                                                    {value}
                                                </Text>
                                            </View>
                                        ),
                                    )}
                                </View>
                                <View
                                    style={{
                                        width: '40%',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                    <View
                                        style={{
                                            position: 'relative',
                                            width: '100%',
                                            height: 150,
                                        }}>
                                        <Image
                                            source={
                                                images[toGoodKey(item?.analysisKey, item?.angle)]
                                            }
                                            style={{
                                                position: 'absolute',
                                                width: '100%',
                                                height: '100%',
                                                borderRadius: 15,
                                                objectFit: 'contain',
                                            }}
                                        />
                                        <Image
                                            source={
                                                images[getKeyName(item?.analysisKey, item?.angle)]
                                            }
                                            style={{
                                                position: 'absolute',
                                                width: '100%',
                                                height: '100%',
                                                borderRadius: 15,
                                                objectFit: 'contain',
                                            }}
                                        />
                                    </View>
                                    <Text style={{ marginTop: 10, fontSize: 12 }}>
                                        {translate('Angle')} : {item?.angle?.toFixed(0)}°
                                    </Text>
                                    <Text style={{ fontSize: 12 }}>
                                        {translate('GoodRange')} : {item?.rangeGood[0]}°{' '}
                                        {item?.rangeGood[1]}°
                                    </Text>
                                </View>
                            </>
                        ) : (
                            <>
                                <View
                                    style={{
                                        width: '40%',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                    <View
                                        style={{
                                            position: 'relative',
                                            width: '100%',
                                            height: 150,
                                        }}>
                                        <Image
                                            source={
                                                images[toGoodKey(item?.analysisKey, item?.angle)]
                                            }
                                            style={{
                                                position: 'absolute',
                                                width: '100%',
                                                height: '100%',
                                                borderRadius: 15,
                                                objectFit: 'contain',
                                            }}
                                        />
                                        <Image
                                            source={
                                                images[getKeyName(item?.analysisKey, item?.angle)]
                                            }
                                            style={{
                                                position: 'absolute',
                                                width: '100%',
                                                height: '100%',
                                                borderRadius: 15,
                                                objectFit: 'contain',
                                            }}
                                        />
                                    </View>
                                    <Text style={{ marginTop: 10, fontSize: 12 }}>
                                        {translate('Angle')} : {item?.angle?.toFixed(0)}°
                                    </Text>
                                    <Text style={{ fontSize: 12 }}>
                                        {translate('GoodRange')} : {item?.rangeGood[0]}°{' '}
                                        {item?.rangeGood[1]}°
                                    </Text>
                                </View>
                                <View style={{ width: '60%' }}>
                                    {Object.entries(item?.segmentedExplanation || {}).map(
                                        ([key, value], i) => (
                                            <View
                                                key={i}
                                                style={{
                                                    flexDirection: 'row',
                                                    alignItems: 'flex-start',
                                                    marginTop: 10,
                                                }}>
                                                <Text
                                                    style={{ fontSize: 12, color: colors.PRIMARY }}>
                                                    <Text
                                                        style={{
                                                            color: colors.SECONDARY,
                                                            fontSize: 12,
                                                        }}>
                                                        {key === 'coach_tips'
                                                            ? translate('CoachTips')
                                                            : key === 'correction'
                                                                ? translate('Correction')
                                                                : translate('Consequences')}{' '}
                                                        :{' '}
                                                    </Text>
                                                    {value}
                                                </Text>
                                            </View>
                                        ),
                                    )}
                                </View>
                            </>
                        )}
                    </View>
                    <View
                        style={{
                            borderBottomWidth: 1,
                            borderBottomColor: 'gray',
                            borderStyle: 'dashed',
                            marginTop: 10,
                        }}
                    />
                </View>
            ))
            }
        </>
    )
}

const styles = StyleSheet.create({

    card: {
        backgroundColor: '#f8f9fa', // Couleur de fond de la carte
        padding: 10,
        borderRadius: 10,
        border: '1px solid #ddd',
        marginTop: -10,
    },
    cardTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    bulletPoint: {
        width: 5,
        height: 5,
        borderRadius: 2.5,
        backgroundColor: 'black',
        marginRight: 5,
    },
    bulletText: {
        fontSize: 12,
    },
    recapContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 5,
    },
    section: {
        padding: 10,
        marginBottom: 10,
    },

});

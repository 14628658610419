import React, { useEffect, useState } from "react";

import TextComponent from "components/atoms/TextComponent";
import MultipleButton from "components/molecules/MultipleButton";

import { translate } from "services/remoteConfig";

import { BODY_2, H6, MEDIUM } from "constant/constants";

function FootPhases({ displayData }) {

    const [phase, setPhase] = useState('Impact');
    const [phaseContent, setPhaseContent] = useState(displayData?.phases?.impact);

    const config = [
        { title: 'Impact', },
        { title: 'Amortisation', },
        { title: 'Propulsion', },
    ];

    useEffect(() => {
        if (phase === 'Impact') {
            setPhaseContent(displayData?.phases?.impact);
        }
        else if (phase === 'Amortisation') {
            setPhaseContent(displayData?.phases?.amortisation);
        }
        else if (phase === 'Propulsion') {
            setPhaseContent(displayData?.phases?.propulsion);
        }
    }, [phase, displayData]);

    return (
        <>
            <TextComponent
                text={translate('FootPhases')}
                weight={MEDIUM}
                variant={H6}
                sx={styles.subtitle}
            />
            <MultipleButton config={config} navigation={phase} setNavigation={setPhase} />
            <TextComponent text={phaseContent} variant={BODY_2} sx={{ padding: '10px', marginBottom: "10vh" }} />
        </>
    )

}

const styles = {
    subtitle: {
        marginBottom: '2vh',
        marginTop: '3vh',
    },
}


export default FootPhases;